import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IonContent, ScrollDetail } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { OpenShiftsService } from 'src/app/modules/admin/services/open-shifts.service';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { ShiftSwapService } from 'src/app/modules/swap-shift/services/shift-swap.service';
import { TimeRecordingService } from 'src/app/modules/time-recording/services/time-recording.service';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';

@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.css'],
})
export class EmployeeDashboardComponent implements OnInit, OnDestroy {
  currentTimestamp = new Date().getTime();
  showRefreshText = false;
  showSpinner = false;
  private timerId: any;
  private destroy$ = new Subject<void>();
  private scrollSubscription: Subscription;

  @ViewChild('content', { static: false }) ionContent: IonContent;
  showWelcomeMessage: boolean;
  fadeOut: boolean;
  isMobile: boolean;

  constructor(
    private calenderServie: CalendarService,
    private shiftSwapService: ShiftSwapService,
    private openShiftService: OpenShiftsService,
    public authService: AuthService,
    public bottomNavigationEmployeeService: BottomNavigationEmployeeService,
    private ngZone: NgZone,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private timeRecordingService: TimeRecordingService
  ) {}

  ngOnInit(): void {
    const body = document.querySelector('body');
    if (body) {
      this.renderer.setStyle(body, 'overflow-y', 'hidden');
    }
    this.timerId = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - this.currentTimestamp;
      // show refresh button if 2 hours have passed since last refresh
      this.showRefreshText = diff > 2 * 60 * 60 * 1000;
      // this.showRefreshText = diff > 8000;
    }, 60000); // check every minute

    this.checkWelcomeMessage();

    // Example subscription to a service with debounceTime
    this.scrollSubscription = this.bottomNavigationEmployeeService.scrollEvent$
      .pipe(takeUntil(this.destroy$), debounceTime(100)) // Adjust the debounce time as needed
      .subscribe((scrollY) => {
        this.ngZone.runOutsideAngular(() => {
          this.handleScroll({
            detail: { scrollTop: scrollY },
          } as CustomEvent<ScrollDetail>);
        });
      });

    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
    this.destroy$.next();
    this.destroy$.complete();
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.bottomNavigationEmployeeService.setScrollableContentElement(
      this.ionContent
    );
  }

  handleRefresh(event) {
    this.showSpinner = true;
    this.currentTimestamp = new Date().getTime();
    this.showRefreshText = false;
    this.openShiftService.getOpenShiftsForEmployee();
    this.shiftSwapService.GetEmployeeSwapShiftsList();
    this.shiftSwapService.GetShiftsForSwapQuery();
    this.calenderServie.getNotAcceptedEventFromEmployee();
    this.calenderServie.getEvent();
    this.timeRecordingService.getDayWeekMonthStatsQuery();

    setTimeout(() => {
      // Any calls to load data go here
      event.target.complete();
      this.showSpinner = false;
    }, 1500);
  }

  checkWelcomeMessage(): void {
    const lastLoginTime = localStorage.getItem('lastLoginTime');
    const welcomeMessageShown = localStorage.getItem('welcomeMessageShown');

    if (!lastLoginTime || !welcomeMessageShown) {
      this.showWelcomeMessage = true;
      localStorage.setItem('welcomeMessageShown', 'true');
      this.authService.setLoginTimestamp();
    } else {
      const lastLoginDate = new Date(lastLoginTime);
      const currentDate = new Date();
      const hoursDifference =
        Math.abs(currentDate.getTime() - lastLoginDate.getTime()) / 36e5;

      if (hoursDifference > 24) {
        this.showWelcomeMessage = true;
        localStorage.setItem('welcomeMessageShown', 'true');
        this.authService.setLoginTimestamp();
      }
    }

    if (this.showWelcomeMessage) {
      setTimeout(() => {
        this.fadeOut = true;
      }, 5000); // Start der Animation nach 5 Sekunden
      setTimeout(() => {
        this.showWelcomeMessage = false;
        this.fadeOut = false; // Zurücksetzen der Klasse für das nächste Mal
      }, 10000); // Entfernen der Nachricht nach 10 Sekunden (5 Sekunden sichtbare Nachricht + 5 Sekunden Animation)
    }
  }

  handleScroll(ev: CustomEvent<ScrollDetail>) {
    this.ngZone.run(() => {
      this.bottomNavigationEmployeeService.emitScrollEvent(ev.detail.scrollTop);
    });
  }
}
