<div class="row justify-content-center">
  <div class="col-xl-6 col-md-6">
    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <img class="center" style="padding-top: 30px; width: 30% !important" src="../../../../../assets/img/Logo.PNG"
          alt="" height="80">
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-12">
            <div class="p-5">
              <mat-horizontal-stepper #stepper>
                <mat-step [stepControl]="firstFormGroup" [completed]="firstStepCompleted">
                  <ng-template matStepLabel>Welcom</ng-template>

                  <p>
                    {{'THANK YOU FOR REGISTERING WITH OUR PLATFORM' | translate}}
                  </p>
                  <p>
                    {{'PLEASE CHECK YOUR EMAIL TO COMPLETE THE REGISTRATION PROCESS' | translate}}
                  </p>
                  <p>
                    {{'IF YOU HAVEN RECEIVED THE EMAIL' | translate}}
                  </p>

                  <p> <b>
                      {{'WELCOME TO FIX-PLANNER! IN THE UPCOMING STEPS' |
                      translate}}</b>
                  </p>
                  <br />
                  <p>
                    {{'READY LET GET STARTED' | translate}}
                  </p>
                  <div class="next-button">
                    <button class="btn-outline-secondary" mat-button matStepperNext>{{'NEXT' | translate}}</button>
                  </div>
                </mat-step>
                <mat-step [stepControl]="isSaveSuccessful" [completed]="secondStepCompleted">
                  <ng-template matStepLabel>{{'Department' | translate}}

                  </ng-template>
                  <div class="alert alert-info" role="alert">
                    <p>
                      {{'ADD DEPARTMENTS TO CATEGORIZE YOUR EMPLOYEES' | translate}}
                    </p>
                  </div>
                  <form class="user" id="addDepartment" #formdepartment='ngForm' autocomplete="off"
                    (submit)="onDepartementSubmit(formModelDepartement)">
                    <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModelDepartement.Id" />
                    <div style="padding-bottom: 10px;" class="row">
                      <div class="form-group col-md-12 mb-6">
                        <label style="padding-bottom: 10px;" for="InputFirstName">{{'DEPARTMENT NAME'|
                          translate}}</label>
                        <input autocomplete="off" type="text" class="form-control" id="Name"
                          aria-describedby="emailHelp" #FirstName="ngModel"
                          placeholder="{{'ENTER DEPARTMENT NAME'| translate}}" name="Name"
                          [(ngModel)]="formModelDepartement.Name" required>
                      </div>
                    </div>
                  </form>
                  <button class="btn btn-primary active" form="addDepartment" type="submit"
                    [disabled]="formdepartment.invalid">
                    {{'Save'| translate}}</button>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
                    <button mat-button matStepperNext (click)="onSecondFormGroupNextButtonClick()"
                      [disabled]="!secondStepSubmitted">{{'NEXT' |
                      translate}}</button>
                  </div>
                </mat-step>
                <mat-step [stepControl]="thirdFormGroup" [completed]="thirdStepCompleted">
                  <ng-template matStepLabel>
                    <spand (click)="onSecondFormGroupNextButtonClick()">{{'QUALIFICATION'| translate}}</spand>
                  </ng-template>
                  <div class="alert alert-info" role="alert">
                    <p>
                      {{'EDIT OR ADD QUALIFICATIONS FOR EACH DEPARTMENT' | translate}}
                    </p>
                  </div>
                  <form class="user" id="addSkill" #formSkill='ngForm' autocomplete="off"
                    (submit)="onSkillSubmit(formModelSkill)">
                    <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModelSkill.Id" />
                    <div class="row">
                      <div class="form-group col-md-6 mb-4">
                        <label for="DepartmentId">{{'Department'| translate}}</label>
                        <mat-select #DepartmentId="ngModel" name="DepartmentId" id="DepartmentId" class="form-control"
                          [(ngModel)]="formModelSkill.DepartmentId">
                          <mat-option *ngFor="let department of departmentList" [value]="department.Id">
                            {{department.Name}}
                          </mat-option>
                        </mat-select>
                      </div>
                      <div class="form-group col-md-6 mb-4">
                        <label for="InputFirstName">{{'QUALIFICATION'| translate}}</label>
                        <input autocomplete="off" type="text" class="form-control" id="Name"
                          aria-describedby="emailHelp" #FirstName="ngModel"
                          placeholder="{{'ENTER QUALIFICATION NAME'| translate}}" name="Name"
                          [(ngModel)]="formModelSkill.Name" required>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6 mb-4">
                        <label for="iconType">{{'ICON CATEGORIES'|translate}}</label>
                        <mat-select (selectionChange)="onIconTypeChange($event)" id="iconType" class="form-control "
                          placeholder="{{'SELECT CATEGORIES FOR SYMBOLS' | translate}}" [formControl]="iconValue"
                          multiple [(ngModel)]="formModelSkill.IconType">
                          <mat-option *ngFor="let iconType of iconTypeList" [value]="iconType.Id">
                            {{iconType.Name}}
                          </mat-option>
                        </mat-select>
                      </div>
                      <div class="form-group col-md-6 mb-4">
                        <label for="Icon">{{'ICON'| translate}}</label>
                        <mat-select #Icon="ngModel" name="Icon" id="Icon" class="form-control"
                          [(ngModel)]="formModelSkill.Icon"
                          [placeholder]="iconList.length === 1 ? ('SELECT CATEGORIES FOR SYMBOLS' | translate) : ''"
                          (selectionChange)="onIconChange($event)">
                          <mat-select-trigger>
                            <span *ngIf="selectedValue">
                              <i [ngClass]="selectedValue.IconCode + ' fa-lg'"></i>
                              {{selectedValue.Name | translate}}
                            </span>
                          </mat-select-trigger>
                          <mat-option *ngFor="let icon of iconList" [value]="icon.Id"> <i
                              [ngClass]="icon.IconCode + ' fa-lg'"></i> {{icon.Name | translate}}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </form>
                  <button class="btn btn-primary active" form="addSkill" type="submit" [disabled]="formSkill.invalid">
                    {{'Save'| translate}}
                  </button>
                  <div class="next-button">
                    <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
                    <button mat-button matStepperNext (click)="onThirdFormGroupNextButtonClick()"
                      [disabled]="!thirdStepSubmitted">{{'NEXT' |
                      translate}}</button>
                  </div>

                </mat-step>
                <mat-step [stepControl]="fourthFormGroup" [completed]="fourthStepCompleted">
                  <ng-template matStepLabel>
                    <spand (click)="onThirdFormGroupNextButtonClick()">{{'EMPLOYER'| translate}}</spand>
                  </ng-template>
                  <div class="alert alert-info" role="alert">
                    <p>
                      {{'ADD AS MANY EMPLOYEES AS NEEDED AND ASSIGN THEM TO DEPARTMENTS AND QUALIFICATIONS' |
                      translate}}
                    </p>
                  </div>
                  <form class="user" id="addEmployee" #form='ngForm' autocomplete="off"
                    (submit)="onEmployeeSubmit(formModelEmployee)">
                    <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModelEmployee.Id" />
                    <div class="row">
                      <div class="form-group col-md-6 mb-4">
                        <label for="InputFirstName">{{'First Name'|translate}}</label>
                        <input autocomplete="off" type="text" class="form-control" id="InputFirstName"
                          aria-describedby="emailHelp" #FirstName="ngModel"
                          placeholder="{{'Enter First Name'|translate}}" name="FirstName"
                          [(ngModel)]="formModelEmployee.FirstName" required>
                      </div>
                      <div class="form-group col-md-6 mb-4">
                        <label for="InputLastName">{{'Last Name'|translate}}</label>
                        <input autocomplete="off" type="text" class="form-control" id="InputLastName"
                          placeholder="{{'Enter Last Name'|translate}}" #LastName="ngModel" name="LastName"
                          [(ngModel)]="formModelEmployee.LastName">
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6 mb-4">
                        <label for="Department">{{'Department'|translate}}</label>
                        <mat-select id="Department" class="form-control "
                          placeholder="{{'Select Department'|translate}}" (selectionChange)="onDepartmentChange($event)"
                          [formControl]="departmentValue" multiple [(ngModel)]="formModelEmployee.DepartmentList"
                          required>
                          <mat-option *ngFor="let department of departmentList"
                            [value]="department.Id">{{department.Name}}
                          </mat-option>
                        </mat-select>
                      </div>
                      <div class="form-group col-md-6 mb-4">
                        <label for="Department">{{'QUALIFICATION'|translate}}</label>
                        <mat-select id="Department" class="form-control " placeholder="{{skillPlaceholderText}}"
                          [formControl]="skillValue" multiple [(ngModel)]="formModelEmployee.SkillList">
                          <mat-option *ngFor="let skill of skillList" [value]="skill.Id"> <i
                              [ngClass]="skill.IconCode"></i> {{skill.Name}}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6 mb-4">
                        <label for="Email">{{'Email'|translate}}</label>
                        <input type="text" class="form-control form-control-user" id="Email"
                          placeholder="{{'Enter Email'|translate}}" #Email="ngModel" name="Email"
                          [(ngModel)]="formModelEmployee.Email">
                      </div>
                      <div class="form-group col-md-6 mb-4">
                        <label for="Role">{{'Role'|translate}}</label>
                        <mat-select #Role="ngModel" name="Role" id="Role" class="form-control"
                          [(ngModel)]="formModelEmployee.Role">
                          <mat-option *ngFor="let role of userRole" [value]="role.id"> {{ role.label }}</mat-option>
                        </mat-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6 mb-4">
                        <label for="Holidays">{{'VACATION DAYS'|translate}}</label>
                        <input type="text" class="form-control form-control-user" id="Holidays"
                          placeholder="{{'Enter number of Holidays'|translate}}" #Holidays="ngModel" name="Holidays"
                          [(ngModel)]="formModelEmployee.Holidays">
                      </div>
                      <div class="form-group col-md-6 mb-4">
                        <label for="WorkingTime">{{'WORK HOURS PER WEEK'|translate}}</label>
                        <input type="text" class="form-control form-control-user" id="InputWorkingTime"
                          placeholder="{{'Enter Working Time'|translate}}" #WorkingTime="ngModel" name="WorkingTime"
                          [(ngModel)]="formModelEmployee.WorkingTime">
                      </div>
                    </div>

                  </form>
                  <button class="btn btn-primary active" form="addEmployee" type="submit">
                    {{'Save'| translate}}
                  </button>
                  <div class="next-button">
                    <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
                    <button mat-button matStepperNext (click)="onFourthFormGroupNextButtonClick()"
                      [disabled]="!fourthStepSubmitted">{{'NEXT' |
                      translate}}</button>
                  </div>

                </mat-step>
                <mat-step [completed]="completed" [state]="state">
                  <ng-template matStepLabel>Done</ng-template>

                  <p>
                    {{'CONGRATULATIONS YOU HAVE SUCCESSFULLY ADDED DEPARTMENTS' | translate}}
                  </p>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
                    <button mat-button (click)="done()">{{'FINISH' | translate}}</button>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>