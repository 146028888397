import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { AddSkill } from '../../model/add-skill';
import { SkillService } from '../../services/skill.service';

@Component({
  selector: 'app-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.css'],
})
export class AddSkillComponent implements OnInit, OnDestroy {
  departementName: string = null;
  departmentList: any[];
  constructor(
    public service: SkillService,
    public departmentService: DepartementService,
    public dialogRef: MatDialogRef<AddSkillComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  private subscriptionDepartmentList: Subscription;
  private subscriptionIconTypeList: Subscription;
  iconValue = new UntypedFormControl();

  iconList = [];
  iconTypeList = [];
  selectedValue: any;
  formModel: any = {
    Id: 0,
    Name: '',
    Icon: '',
    DepartmentId: null,
    IconType: null,
  };

  ngOnInit(): void {
    if (this.data && this.data.skill && this.data.skill.Id) {
      this.formModel = {
        Id: this.data.skill.Id,
        Name: this.data.skill.Name,
        Icon: this.data.skill.Icon.Id,
        DepartmentId: this.data.skill.DepartmentId,
        IconType: [this.data.skill.Icon.IconTypeId],
      };
      this.selectedValue = this.data.skill.Icon;
      this.service.getSkillIconsList([this.data.skill.Icon.IconTypeId]);
    }

    this.subscriptionDepartmentList = this.service.iconList.subscribe(
      (list) => {
        this.iconList = [
          { Name: this.translate.instant('NO SELECTION'), Id: null },
          ...list,
        ];
      }
    );

    this.subscriptionIconTypeList = this.service.iconTypeList.subscribe(
      (list) => {
        this.iconTypeList = list;
      }
    );

    this.service.getIconTypeList();

    this.subscriptionDepartmentList =
      this.departmentService.departementList.subscribe((list) => {
        this.departmentList = [
          { Name: this.translate.instant('NO SELECTION'), Id: null },
          ...list,
        ];
      });
    this.departmentService.getDepartementList();
  }

  onSubmit(form: NgForm): void {
    if (form.value) {
      this.service.submitSkill({
        Id: form.value.Id,
        Name: form.value.Name,
        IconId: form.value.Icon,
        DepartmentId: form.value.DepartmentId,
      } as AddSkill);
      this.onClose();
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
  onDivisionChange(): void {
    this.service.getSkillIconsList([]);
  }

  onIconTypeChange(event): void {
    this.service.getSkillIconsList(event.value);
  }

  onIconChange(event): void {
    const id = event.value;
    this.selectedValue = this.iconList.filter((x) => x.Id === id)[0];
  }

  ngOnDestroy(): void {
    this.subscriptionDepartmentList.unsubscribe();
    this.subscriptionIconTypeList.unsubscribe();
  }
}
