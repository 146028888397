<mat-tab-group>
  <!-- Arbeitszeiten Tab -->
  <mat-tab label="{{'WORK' | translate}}">
    <div class="tab-content">
      <table class="settings-table">
        <tr>
          <td><label class="form-label">{{ 'MAX DAILY WORKING HOURS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.MaxDailyWorkingHours"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'MAX WEEKLY WORKING HOURS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.MaxWeeklyWorkingHours"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'MIN DAILY WORKING HOURS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.MinDailyWorkingHours"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'MIN WEEKLY WORKING HOURS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.MinWeeklyWorkingHours"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'MAX CONSECUTIVE WORK DAYS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.MaxConsecutiveWorkDays"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'WORK TIME WINDOW START' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showTimepicker="true"
              [(ngModel)]="companySettings.TimeTrackingSettings.WorkTimeWindow.Start"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'WORK TIME WINDOW END' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showTimepicker="true"
              [(ngModel)]="companySettings.TimeTrackingSettings.WorkTimeWindow.End"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'ALLOW EMPLOYEE TO DELETE ENTRIES' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.EmployeeSettings.CanDeleteTimeRecord ? ('YES' | translate) : ('NO'
              | translate) }}
            </label>
            <mat-slide-toggle color="primary"
              [checked]="companySettings.TimeTrackingSettings.EmployeeSettings.CanDeleteTimeRecord"
              (change)="onUpdate()" name="CanDeleteTimeRecord"
              [(ngModel)]="companySettings.TimeTrackingSettings.EmployeeSettings.CanDeleteTimeRecord">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'ALLOW EMPLOYEE TO EDIT ENTRIES' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.EmployeeSettings.CanEditTimeRecord ? ('YES' | translate) : ('NO' |
              translate) }}
            </label>
            <mat-slide-toggle color="primary"
              [checked]="companySettings.TimeTrackingSettings.EmployeeSettings.CanEditTimeRecord" (change)="onUpdate()"
              name="CanEditTimeRecord"
              [(ngModel)]="companySettings.TimeTrackingSettings.EmployeeSettings.CanEditTimeRecord">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'ALLOW EMPLOYEE TO ADD ENTRIES' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.EmployeeSettings.CanAddTimeRecord ? ('YES' | translate) : ('NO' |
              translate) }}
            </label>
            <mat-slide-toggle color="primary"
              [checked]="companySettings.TimeTrackingSettings.EmployeeSettings.CanAddTimeRecord" (change)="onUpdate()"
              name="CanAddTimeRecord"
              [(ngModel)]="companySettings.TimeTrackingSettings.EmployeeSettings.CanAddTimeRecord">
            </mat-slide-toggle>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <!-- Ruhe- und Pausenzeiten Tab -->
  <mat-tab label="{{'BREAK' | translate}}">
    <div class="tab-content">
      <table class="settings-table">
        <tr>
          <td><label class="form-label">{{ 'BREAK TIME' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.BreakTime"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'AFTER HOURS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.AfterHours"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'DAILY REST PERIOD' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.RestPeriods.DailyRestPeriod"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'WEEKLY REST PERIOD' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.RestPeriods.WeeklyRestPeriod"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'HOLIDAY REST PERIOD' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.RestPeriods.HolidayRestPeriod"></app-input-editable>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <!-- Gleitzeit und Überstunden Tab -->
  <mat-tab label="{{'FLEXTIME' | translate}}">
    <div class="tab-content">
      <table class="settings-table">
        <tr>
          <td><label class="form-label">{{ 'FLEXTIME' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.Flextime.IsFlextime ? ('YES' | translate) : ('NO' | translate) }}
            </label>
            <mat-slide-toggle color="primary" [checked]="companySettings.TimeTrackingSettings.Flextime.IsFlextime"
              (change)="onUpdate()" name="Flextime"
              [(ngModel)]="companySettings.TimeTrackingSettings.Flextime.IsFlextime">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr *ngIf="companySettings.TimeTrackingSettings.Flextime.IsFlextime">
          <td><label class="form-label">{{ 'CORE TIME START' |
              translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showTimepicker="true"
              [(ngModel)]="companySettings.TimeTrackingSettings.Flextime.CoreTimeStart"></app-input-editable>
          </td>
        </tr>
        <tr *ngIf="companySettings.TimeTrackingSettings.Flextime.IsFlextime">
          <td><label class="form-label">{{ 'CORE TIME END' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showTimepicker="true"
              [(ngModel)]="companySettings.TimeTrackingSettings.Flextime.CoreTimeEnd"></app-input-editable>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'OVERTIME' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.Overtime.IsOvertime ? ('YES' | translate) : ('NO' | translate) }}
            </label>
            <mat-slide-toggle color="primary" [checked]="companySettings.TimeTrackingSettings.Overtime.IsOvertime"
              (change)="onUpdate()" name="Overtime"
              [(ngModel)]="companySettings.TimeTrackingSettings.Overtime.IsOvertime">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr *ngIf="companySettings.TimeTrackingSettings.Overtime.IsOvertime">
          <td><label class="form-label">{{ 'MAX OVERTIME HOURS' | translate }}</label></td>
          <td>
            <app-input-editable (onSave)="onUpdate()" showNormalInput="true" validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.Overtime.MaxOvertimeHours"></app-input-editable>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <!-- Benachrichtigungen und Genehmigungen Tab -->
  <mat-tab label="{{'NOTIFICATIONS' | translate}}">
    <div class="tab-content">
      <table class="settings-table">
        <tr>
          <td><label class="form-label">{{ 'ENABLE NOTIFICATIONS' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.Notifications.Enable ? ('YES' | translate) : ('NO' | translate) }}
            </label>
            <mat-slide-toggle color="primary" [checked]="companySettings.TimeTrackingSettings.Notifications.Enable"
              (change)="onUpdate()" name="Notifications"
              [(ngModel)]="companySettings.TimeTrackingSettings.Notifications.Enable">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'EMAIL NOTIFICATIONS' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.Notifications.Email ? ('YES' | translate) : ('NO' | translate) }}
            </label>
            <mat-slide-toggle color="primary" [checked]="companySettings.TimeTrackingSettings.Notifications.Email"
              (change)="onUpdate()" name="EmailNotifications"
              [(ngModel)]="companySettings.TimeTrackingSettings.Notifications.Email">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td><label class="form-label">{{ 'MOBILE TIME TRACKING ENABLE' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.MobileTimeTracking.Enable ? ('YES' | translate) : ('NO' |
              translate) }}
            </label>
            <mat-slide-toggle color="primary" [checked]="companySettings.TimeTrackingSettings.MobileTimeTracking.Enable"
              (change)="onUpdate()" name="MobileTimeTracking"
              [(ngModel)]="companySettings.TimeTrackingSettings.MobileTimeTracking.Enable">
            </mat-slide-toggle>
          </td>
        </tr>
        <tr *ngIf="companySettings.TimeTrackingSettings.MobileTimeTracking.Enable">
          <td><label class="form-label">{{ 'GPS TRACKING' | translate }}</label></td>
          <td>
            <label class="form-label" style="padding-left: 30px; padding-right: 10px;">
              {{ companySettings.TimeTrackingSettings.MobileTimeTracking.GPSTracking ? ('YES' | translate) : ('NO' |
              translate) }}
            </label>
            <mat-slide-toggle color="primary"
              [checked]="companySettings.TimeTrackingSettings.MobileTimeTracking.GPSTracking" (change)="onUpdate()"
              name="GPSTracking" [(ngModel)]="companySettings.TimeTrackingSettings.MobileTimeTracking.GPSTracking">
            </mat-slide-toggle>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>