import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { AuthService } from '../../services/auth/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  formModel = {
    Email: '',
    Password: '',
  };
  errorStatus: any;
  constructor(
    private route: ActivatedRoute,
    private service: AuthService,
    private router: Router
  ) {
    if (
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.param
    ) {
      localStorage.setItem('token', this.route.snapshot.queryParams.param);
    }
    this.errorStatus = this.service.error;
  }

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      if (this.service.isManegeOrTeamLeader) {
        this.router.navigateByUrl('/dashboard');
      } else {
        this.router.navigateByUrl('/employeedashboard');
      }
    } else if (this.service.Email != null) {
      this.formModel.Email = this.service.Email;
      this.formModel.Password = null;
    }
  }

  async onSubmit(form: NgForm): Promise<void> {
    this.service.login(form.value);
    if (Capacitor.getPlatform() === 'ios') {
      await SavePassword.promptDialog({
        username: form.value.Email,
        password: form.value.Password,
      });
    }
  }

  loginExternal(): void {
    this.service.loginExternal();
  }
}
