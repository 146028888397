import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AddDepartement } from 'src/app/modules/departement/model/add-departement';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { Employee } from 'src/app/modules/employee/model/employee.model';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { AddSkill } from 'src/app/modules/skill/model/add-skill';
import { Skill } from 'src/app/modules/skill/model/departement';
import { SkillService } from 'src/app/modules/skill/services/skill.service';

@Component({
  selector: 'app-registered-stepper',
  templateUrl: './registered-stepper.component.html',
  styleUrls: ['./registered-stepper.component.css'],
})
export class RegisteredStepperComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  skillList = [{ Name: 'Select first a Department', Id: null }];

  departmentValue = new UntypedFormControl();
  skillValue = new UntypedFormControl();
  completed: boolean = true;
  state = 'error';

  departmentList: any[];
  selectedValue: any;
  iconList = [];

  skillPlaceholderText = this.translate.instant('FIRST CHOOSE A DEPARTMENT');

  private subscriptionDepartmentList: Subscription;
  private subscriptionIconTypeList: Subscription;
  private subscriptionSkillList: Subscription;

  // In your component class
  firstStepCompleted = false;
  secondStepCompleted = false;
  thirdStepCompleted = false;
  fourthStepCompleted = false;

  secondStepSubmitted = false;
  thirdStepSubmitted = false;
  fourthStepSubmitted = false;

  formModelDepartement: AddDepartement = {
    Id: 0,
    Name: '',
  };

  formModelSkill: any = {
    Id: 0,
    Name: '',
    Icon: '',
    DepartmentId: null,
  };

  formModelEmployee = {
    Id: 0,
    FirstName: '',
    LastName: '',
    Gender: 0,
    CivilStatus: 0,
    MobileNo: '',
    HomePhoneNo: '',
    Street1: '',
    City: '',
    ZipCode: '',
    ProfileImage: '',
    DepartmentList: null,
    Department: null,
    WorkingTime: 0,
    Role: 2,
    Email: '',
    Holidays: 0,
    SkillList: null,
  };

  userRole = [
    { id: 2, label: 'Employee' },
    { id: 3, label: 'Team Leader' },
  ];

  iconTypeList = [];
  iconValue = new UntypedFormControl();

  isSaveSuccessful = true;

  constructor(
    private _formBuilder: FormBuilder,
    public departementService: DepartementService,
    public skillService: SkillService,
    private translate: TranslateService,
    public employeeService: EmployeeService,
    public router: Router
  ) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });

    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });

    this.fourthFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });

    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = [{ Name: 'Nothing', Id: null }, ...list];
      });

    this.subscriptionDepartmentList = this.skillService.iconList.subscribe(
      (list) => {
        this.iconList = [{ Name: 'Nothing', Id: null }, ...list];
      }
    );

    this.subscriptionSkillList = this.skillService.SkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
      }
    );

    this.subscriptionIconTypeList = this.skillService.iconTypeList.subscribe(
      (list) => {
        this.iconTypeList = list;
      }
    );

    this.skillService.getIconTypeList();
  }

  done() {
    this.completed = true;
    this.state = 'done';
    this.router.navigateByUrl('/planner');
  }

  onDepartementSubmit(form: any): void {
    if (form) {
      this.departementService.submitDepartement(form);
      this.isSaveSuccessful = true;
      this.secondStepSubmitted = true;
    }
  }

  onIconChange(event): void {
    const id = event.value;
    this.selectedValue = this.iconList.filter((x) => x.Id === id)[0];
  }

  onSkillSubmit(form: any): void {
    if (form) {
      this.skillService.submitSkill({
        Id: form.Id,
        Name: form.Name,
        IconId: form.Icon,
        DepartmentId: form.DepartmentId,
      } as AddSkill);
      this.thirdStepSubmitted = true;
    }
  }

  onIconTypeChange(event): void {
    this.skillService.getSkillIconsList(event.value);
  }

  onEmployeeSubmit(form: any): void {
    if (form) {
      this.employeeService.addEmployer(form as Employee);
      this.fourthStepSubmitted = true;
    }
  }

  onDepartmentChange(event) {
    this.skillList = [];
    this.skillService.getSkillList(event.value);
    if (event.value.length > 0) {
      this.skillPlaceholderText = this.translate.instant(
        'CHOOSE A QUALIFICATION'
      );
    } else {
      this.skillPlaceholderText = this.translate.instant(
        'FIRST CHOOSE A DEPARTMENT'
      );
    }
  }
  getDepartements() {
    this.departementService.getDepartementList();
  }

  ngOnDestroy(): void {
    this.subscriptionDepartmentList.unsubscribe();
    this.subscriptionSkillList.unsubscribe();
    this.subscriptionIconTypeList.unsubscribe();
  }
  onSecondFormGroupNextButtonClick() {
    this.getDepartements();
    this.secondStepCompleted = true;
  }

  onThirdFormGroupNextButtonClick() {
    this.getDepartements();
    this.thirdStepCompleted = true;
  }
  onFourthFormGroupNextButtonClick() {
    this.fourthStepCompleted = true;
  }
}
