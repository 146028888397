<full-calendar [hidden]="!eventNumber" [deepChangeDetection]="true" #calendar [options]="calendarOptions"></full-calendar>
<div *ngIf="eventNumber === 0" class="alert alert-info" role="alert" style="margin-top: 50px;">
    {{alertMessage}}
</div>
<div [hidden]="!eventNumber" #EmployeeSearchInput>

    <div class="row">
        <div class="form-group col-lg-10" style="padding-right: 0px; width: 75%">
            <input type="text" class="form-control form-control-user" id="InputHomePhoneNo" style="font-family:Arial, FontAwesome" (ngModelChange)="onSearchKeywordsChange($event)" placeholder="&#xF002; {{'FILTER EMPLOYEES'|translate}}" #HomePhoneNo="ngModel" name="HomePhoneNo"
                [(ngModel)]="EmployeeSearchText">
        </div>
        <div class="form-group col-lg-2" style="padding-left: 0px;">
            <button mat-button class="padding-left" style="padding-left: 0px;" [matMenuTriggerFor]="calSettings"><mat-icon>settings</mat-icon></button>
            <mat-menu #calSettings="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="vertebrates">{{'FILTER'| translate}}</button>
            </mat-menu>

            <mat-menu #vertebrates="matMenu">
                <div mat-menu-item (keydown.enter)="bss.toggle()">
                    <mat-checkbox [disabled]="this.currentCalendarData?.currentViewType !== 'customDay'" class="mat-menu__checkbox" #bss [matTooltip]="this.currentCalendarData?.currentViewType !== 'customDay' ? translatedTextOnlyDayView : ''" matTooltipPosition="right">
                        {{'BY SHIFT START'| translate}}
                    </mat-checkbox>
                </div>
                <div mat-menu-item (click)="$event.stopPropagation(); bat.toggle();" (keydown.enter)="$event.stopPropagation();">
                    <mat-checkbox class="mat-menu__checkbox" #bat>
                        {{'BY AVAILABLE TIME'| translate}}
                    </mat-checkbox>
                </div>
                <!-- <button mat-menu-item>Birds</button>
        <button mat-menu-item>Mammals</button> -->
            </mat-menu>
        </div>
    </div>

</div>
<div id="download">
    <img style="display: none;" #canvas>
    <a #downloadLink></a>
</div>