<div class="modal-dialog modal-lg" role="document" style="max-width: 500px">
  <div class="modal-content">
    <div
      class="modal-header text-center"
      style="padding-bottom: 0px; margin-bottom: 0px"
    >
      <h5 class="modal-title w-100">
        {{ "WORK SHIFT DETAILS" | translate }} <b>{{ dialogTitle }}</b>
      </h5>
    </div>
    <hr class="mt-2 mb-3" />
    <div class="row" style="padding-top: 15px">
      <div class="col-12">
        <h6>{{ employeeName }}</h6>
      </div>
    </div>

    <div
      *ngIf="
        clockEvent.ComplianceMessages &&
        clockEvent.ComplianceMessages.length > 0
      "
      style="margin: 5px"
    >
      <hr class="mt-2 mb-3" />
      <div
        *ngFor="let message of clockEvent.ComplianceMessages"
        class="alert alert-warning d-flex align-items-center"
        role="alert"
        style="padding: 5px"
      >
        <i
          class="fa fa-exclamation-triangle fa-xl mr-2"
          style="padding-right: 10px"
        ></i>
        <span>{{
          message[0] | translate : { value1: message[1], value2: message[2] }
        }}</span>
      </div>
    </div>
    <hr class="mt-2 mb-3" />
    <div class="modal-body" style="min-width: 330px">
      <div class="row">
        <div class="col-8">
          <h4>{{ shiftTime.StarTime }} - {{ shiftTime.EndTime }}</h4>
        </div>
        <div class="col-4">
          <h5><i class="fa-solid fa-clock"></i> {{ duration }}</h5>
          <h5><i class="fa-light fa-mug-hot"></i> {{ break }}</h5>
          <h5 *ngIf="overTime">
            <i class="fa-light fa-alarm-plus"></i> {{ overTime }}
          </h5>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12" *ngIf="clockEvent && clockEvent.ClockEvents">
          <app-time-recording-table
            (clockEvent)="handleClockEvent($event)"
            [clockEventList]="clockEvent.ClockEvents"
            [employeeId]="data.employeeId"
            [departmentId]="data.departmentId"
            [skillId]="data.skillId"
            [startDate]="data.startDate"
          ></app-time-recording-table>
        </div>
        <div
          class="col-12"
          *ngIf="
            !clockEvent ||
            !clockEvent.ClockEvents ||
            clockEvent.ClockEvents.length === 0
          "
        >
          <p>{{ "NO ENTRIES AVAILABLE" | translate }}</p>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button
        class="btn btn-outline-secondary"
        (click)="onClose()"
        type="button"
        data-dismiss="modal"
      >
        {{ "Close" | translate }}
      </button>
    </div>
  </div>
</div>
