<div>
  <div *ngIf="listData.length === 0" class="alert alert-info" role="alert">
    {{alertMessage}}
  </div>
  <table class="table">
    <tbody>
      <tr *ngFor="let departement of listData; let i = index" [class.active-row]="i === activeRowIndex"
        (click)="setActiveRow(i); getRecord(departement.Id,departement.Name)" style="cursor: pointer;">
        <td style="float: left" class="col-md">
          {{departement.Name}}
        </td>
        <td style="float: right;" class="col-md">
          {{departement.EmployeeCount}}
        </td>
        <td class="col-md">
          <button style="float: right;" mat-icon-button [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onDepartementeUpdate(departement.Id)">
              <mat-icon>edit</mat-icon>
              <span>{{'EDIT'| translate}}</span>
            </button>
            <button mat-menu-item (click)="onDepartementeDelete(departement.Id, departement.Name)">
              <mat-icon>delete</mat-icon>
              <span>{{'DELETE'| translate}}</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>