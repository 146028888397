import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DepartementService } from '../../services/departement.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { DivisionService } from 'src/app/modules/Division/services/division.service';
import { Division } from 'src/app/modules/Division/model/division';
import { AddDepartement } from '../../model/add-departement';

@Component({
  selector: 'app-add-departement',
  templateUrl: './add-departement.component.html',
  styleUrls: ['./add-departement.component.css'],
})
export class AddDepartementComponent implements OnInit {
  departementName: string = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public service: DepartementService,
    public serviceDivision: DivisionService,
    public dialogRef: MatDialogRef<AddDepartementComponent>
  ) {}

  formModel: AddDepartement = {
    Id: 0,
    Name: '',
  };
  ngOnInit(): void {
    if (this.data && this.data.departement && this.data.departement.Id) {
      this.formModel = {
        Id: this.data.departement.Id,
        Name: this.data.departement.Name,
      } as AddDepartement;
    }
  }

  onSubmit(form: NgForm): void {
    if (form.value) {
      this.service.submitDepartement(form.value);
      this.onClose();
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
  onDivisionChange(): void {
    this.service.getDepartementList();
  }
}
