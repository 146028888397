<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center" style="text-align: center;">
      <h5 class="modal-title w-100" *ngIf="formModel.Id>0" id="exampleModalCenterTitle">{{'Edit employee
        data'|translate}}
      </h5>
      <h5 class="modal-title w-100" *ngIf="formModel.Id===0" id="exampleModalCenterTitle">{{'Add New
        Employer'|translate}}
      </h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(formModel)">
        <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModel.Id" />
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="InputFirstName">{{'First Name'|translate}}</label>
            <input autocomplete="off" type="text" class="form-control" id="InputFirstName" aria-describedby="emailHelp"
              #FirstName="ngModel" placeholder="{{'Enter First Name'|translate}}" name="FirstName"
              [(ngModel)]="formModel.FirstName" required>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="InputLastName">{{'Last Name'|translate}}</label>
            <input autocomplete="off" type="text" class="form-control" id="InputLastName"
              placeholder="{{'Enter Last Name'|translate}}" #LastName="ngModel" name="LastName"
              [(ngModel)]="formModel.LastName">
          </div>
        </div>
        <div class="row">
          <div style="width: 350px;" class="form-group col-md-6 mb-4">
            <label for="Department">{{'Department'|translate}}</label>
            <mat-select id="Department" class="form-control" placeholder="{{'Select Department'|translate}}"
              (selectionChange)="onDepartmentChange($event)" [formControl]="departmentValue" multiple
              [(ngModel)]="formModel.DepartmentList" required>
              <mat-option *ngFor="let department of departmentList"
                [value]="department.Id">{{department.Name}}</mat-option>
            </mat-select>
          </div>
          <div style="width: 350px;" class="form-group col-md-6 mb-4">
            <label for="Department">{{'QUALIFICATION'|translate}}</label>
            <mat-select id="Department" class="form-control" placeholder="{{skillPlaceholderText}}"
              [formControl]="skillValue" multiple [(ngModel)]="formModel.SkillList">
              <mat-option *ngFor="let skill of skillList" [value]="skill.Id"> <i [ngClass]="skill.IconCode"></i>
                {{skill.Name}}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Email">{{'Email'|translate}}</label>
            <input type="email" class="form-control form-control-user" id="Email" [disabled]="employeeHaseEmail"
              placeholder="{{'Enter Email'|translate}}" #Email="ngModel" name="Email" [(ngModel)]="formModel.Email"
              [required]="formModel.Role === 3">
            <div *ngIf="Email.invalid" class="text-danger">
              <div *ngIf="Email.errors?.required">
                {{'TO SELECT A TEAM LEADER YOU MUST HAVE AN EMAIL ADDRESS'|translate}}
              </div>
              <div *ngIf="Email.errors?.email">
                {{'Please enter a valid email address.'|translate}}
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="Role">{{'Role'|translate}}</label>
            <mat-select *ngIf="formModel.Role!==1" #Role="ngModel" name="Role" id="Role" class="form-control"
              [(ngModel)]="formModel.Role" [disabled]="authService.myRole !== 1">
              <mat-option *ngFor="let role of userRole" [value]="role.id"> {{ role.label }}</mat-option>
            </mat-select>
            <ng-container *ngIf="formModel.Role === 1">
              <br>
              <label for="Role">{{'MANAGER'|translate}}</label>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Holidays">{{'VACATION DAYS'|translate}}</label>
            <input type="text" class="form-control form-control-user" id="Holidays"
              placeholder="{{'Enter number of Holidays'|translate}}" #Holidays="ngModel" name="Holidays"
              [(ngModel)]="formModel.Holidays">
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="JobType">{{'TYPES OF EMPLOYMENT'|translate}}</label>
            <mat-select id="JobType" class="form-control" placeholder="{{'Select Job Type'|translate}}" name="jobType"
              [(ngModel)]="formModel.JobType">
              <mat-option *ngFor="let jobType of jobTypes" [value]="jobType.id">{{jobType.label}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row" *ngIf="formModel.JobType !== 3">
          <div class="form-group col-md-6 mb-4">
            <label for="WorkDaysPerWeek">{{'WORK DAYS PER WEEK'|translate}}</label>
            <mat-select id="WorkDaysPerWeek" class="form-control" name="WorkDaysPerWeek"
              placeholder="{{'Select Work Days Per Week'|translate}}" [(ngModel)]="formModel.WorkDaysPerWeek">
              <mat-option *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]" [value]="day">{{day}}</mat-option>
            </mat-select>
          </div>

          <div class="form-group col-md-6 mb-4">
            <label for="WorkHoursPerDay">{{'WORK HOURS PER DAY'|translate}}</label>
            <input #End="ngModel" name="End" [min]="minEndTime" [max]="maxEndTime" id="End" class="form-control"
              [format]="24" [ngxMatTimepicker]="pickerEnd" [(ngModel)]="hour.hoursPerDay" required inputmode='none'
              aria-describedby="end-time-icon">
            <ngx-mat-timepicker #pickerEnd [minutesGap]="30"></ngx-mat-timepicker>
          </div>
        </div>
        <div class="row">
          <div *ngIf="formModel.JobType === 3" class="form-group col-md-6 mb-4">
            <label for="WorkingTime">{{'WORK HOURS PER WEEK'|translate}}</label>
            <input type="text" class="form-control form-control-user" id="InputWorkingTime"
              placeholder="{{'Enter Working Time'|translate}}" #WorkingTime="ngModel" name="WorkingTime"
              [(ngModel)]="formModel.WorkingTime">
          </div>
        </div>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{'MORE EMPLOYEE INFORMATION'|translate}}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="InputHomePhoneNo">{{'Home Phone'|translate}}</label>
                <input type="text" class="form-control form-control-user" id="InputHomePhoneNo"
                  placeholder="{{'Enter HomePhone Number'|translate}}" #HomePhoneNo="ngModel" name="HomePhoneNo"
                  [(ngModel)]="formModel.HomePhoneNo">
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="InputMobileNo">{{'Mobile Number'|translate}}</label>
                <input type="text" class="form-control form-control-user" id="InputMobileNo"
                  placeholder="{{'Mobile Number'|translate}}" #MobileNo="ngModel" name="MobileNo"
                  [(ngModel)]="formModel.MobileNo">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="InputZipCode">{{'ZipCode'|translate}}</label>
                <input type="text" class="form-control form-control-user" id="InputZipCode"
                  placeholder="{{'Enter ZipCode'|translate}}" #ZipCode="ngModel" name="ZipCode"
                  [(ngModel)]="formModel.ZipCode">
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="City">{{'City'|translate}}</label>
                <input type="text" class="form-control form-control-user" id="InputCity"
                  placeholder="{{'City'|translate}}" #City="ngModel" name="City" [(ngModel)]="formModel.City">
              </div>
            </div>
            <div class="row">
              <div class="form-outline col-md-6 mb-4">
                <label for="InputStreet1">{{'Street'|translate}}</label>
                <input type="text" class="form-control form-control-user" id="InputStreet1"
                  placeholder="{{'Street'|translate}}" #Street1="ngModel" name="Street1"
                  [(ngModel)]="formModel.Street1">
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
        data-dismiss="modal">{{'Close'|translate}}</button>
      <button class="btn btn-primary active" form="addEmployee" type="submit" [disabled]="form.invalid">{{'Save
        changes'|translate}}</button>
    </div>
  </div>
</div>