<div class="row">
  <div class="col-6">
    <label class="form-label">{{ "COMPANY" | translate }}</label>
  </div>
  <div class="col-6">
    <app-input-editable
      showNormalInput="true"
      (onSave)="onUpdate($event)"
      [(ngModel)]="companySettings.CompanyName"
    ></app-input-editable>
  </div>
</div>
<hr class="mt-2 mb-3" />
<div class="row">
  <div class="form-group col-6">
    <label class="form-label">{{ "TIME ZONE" | translate }}</label>
  </div>
  <div class="form-group col-6">
    <app-input-editable
      showDropdownInput="true"
      [dropdownListe]="timeZones"
      (onSave)="onUpdate($event)"
      [(ngModel)]="companySettings.TimeZone"
    >
    </app-input-editable>
  </div>
</div>
<div class="row">
  <div class="form-group col-6">
    <label class="form-label">{{ "WEEK START" | translate }}</label>
  </div>
  <div class="form-group col-6">
    <app-input-editable
      showDropdownInput="true"
      [dropdownListe]="daysOfWeek"
      (onSave)="onUpdate($event)"
      [(ngModel)]="companySettings.CalendarOptions.FirstDay"
    ></app-input-editable>
  </div>
</div>
<div class="row">
  <div class="form-group col-6">
    <label class="form-label">{{ "BUSINESS HOURS START" | translate }}</label>
  </div>
  <div class="form-group col-6">
    <app-input-editable
      showTimepicker="true"
      (onSave)="onUpdate($event)"
      [(ngModel)]="companySettings.CalendarOptions.BusinessHoursStartTime"
    ></app-input-editable>
  </div>
</div>
<div class="row">
  <div class="form-group col-6">
    <label class="form-label">{{ "BUSINESS HOURS END" | translate }}</label>
  </div>
  <div class="form-group col-6">
    <app-input-editable
      showTimepicker="true"
      (onSave)="onUpdate($event)"
      [(ngModel)]="companySettings.CalendarOptions.BusinessHoursEndTime"
    ></app-input-editable>
  </div>
</div>
