import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { Skill } from '../model/departement';

import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Icon } from 'src/app/core/_models/icon';
import { IconType } from 'src/app/core/_models/icon-type';
import { AddSkill } from '../model/add-skill';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private skillIconListSource: BehaviorSubject<Icon[]> = new BehaviorSubject(
    []
  );
  iconList = this.skillIconListSource.asObservable();

  private iconTypesListSource: BehaviorSubject<IconType[]> =
    new BehaviorSubject([]);
  iconTypeList = this.iconTypesListSource.asObservable();

  private SkillListSource: BehaviorSubject<Skill[]> = new BehaviorSubject([]);
  SkillList = this.SkillListSource.asObservable();

  private EmployeeSkillListSource: BehaviorSubject<Skill[]> =
    new BehaviorSubject([]);
  EmployeeSkillList = this.EmployeeSkillListSource.asObservable();

  private skillEmployeeListSource: BehaviorSubject<any[]> = new BehaviorSubject(
    []
  );
  departementEmployeeList = this.skillEmployeeListSource.asObservable();

  public currentDateTime = Date.now();

  getSkillIconsList(values: number[]): void {
    // Check if values is empty
    if (!values || values.length === 0) {
      this.skillIconListSource.next([]);
      return;
    }

    // Create HttpParams object and set the typeIds parameter
    let params = new HttpParams();
    values.forEach((value) => {
      params = params.append('typeIds', value.toString());
    });

    const apiUrl = `${ApiEndPoints.IconList}`;

    // Make the HTTP GET request with the params
    this.httpClient.requestCall(apiUrl, ApiMethod.GET, null, params).subscribe(
      (res) => {
        const SkillIconList = res as Icon[];
        this.skillIconListSource.next(SkillIconList);
      },
      (error) => {
        console.error('Error fetching skill icons:', error);
      }
    );
  }

  getIconTypeList(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetIconTypes, ApiMethod.GET)
      .subscribe((res) => {
        const iconTypeList = res as IconType[];
        this.iconTypesListSource.next(iconTypeList);
      });
  }

  getSkillList(departmentId: number | []): void {
    const departmentListId =
      typeof departmentId === 'number' ? [departmentId] : departmentId;

    if (departmentListId?.length > 0) {
      const departmentListIdString = departmentListId.join(',');
      this.httpClient
        .requestCall(
          ApiEndPoints.SkillList + '/' + departmentListIdString,
          ApiMethod.GET
        )
        .subscribe((res) => {
          const SkillList = res as Skill[];
          this.SkillListSource.next(SkillList);
        });
    }

    // if (departmentId) {
    //   this.httpClient
    //     .requestCall(ApiEndPoints.SkillList + '/' + departmentId, ApiMethod.GET)
    //     .subscribe((res) => {
    //       const SkillList = res as Skill[];
    //       this.SkillListSource.next(SkillList);
    //     });
    // }
  }

  getSkillById(id): Skill {
    return this.SkillListSource.value.filter((x) => x.Id === id)[0];
  }

  getEmployeeSkillList(employeeId: number): void {
    this.httpClient
      .requestCall(
        ApiEndPoints.EmployeeSkillList + '/' + employeeId,
        ApiMethod.GET
      )
      .subscribe((res) => {
        const SkillList = res as Skill[];
        this.EmployeeSkillListSource.next(SkillList);
      });
  }
  updateSkillWeightingList(SkillList: Skill[]): void {
    this.httpClient
      .requestCall(ApiEndPoints.UpdateSkillWeighting, ApiMethod.POST, SkillList)
      .subscribe((res) => {});
  }

  submitSkill(skill: AddSkill): HttpService {
    if (skill.Id) {
      return this.httpClient
        .requestCall(ApiEndPoints.UpdateSkill, ApiMethod.POST, skill)
        .subscribe((res: any) => {
          if (res > 0) {
            this.toastr.success(
              this.translate.instant('NEW QUALIFICATION ADDED SUCCESSFULLY'),
              this.translate.instant('SUCCESS')
            );
            this.getSkillList(skill.DepartmentId);
          }
        });
    }

    return this.httpClient
      .requestCall(ApiEndPoints.AddSkill, ApiMethod.POST, skill)
      .subscribe((res: any) => {
        if (res > 0) {
          this.toastr.success(
            this.translate.instant('NEW QUALIFICATION ADDED SUCCESSFULLY'),
            this.translate.instant('SUCCESS')
          );
          this.getSkillList(skill.DepartmentId);
        }
      });
  }

  deleteSkill(Id: number): void {
    this.httpClient
      .requestCall(ApiEndPoints.DeleteSkill, ApiMethod.POST, {
        Id,
      })
      .subscribe((res) => {
        if (res) {
          const departementId = this.SkillListSource.value.filter(
            (x) => x.Id === Id
          )[0].DepartmentId;
          this.getSkillList(departementId);
        }
      });
  }
}
