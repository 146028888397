export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum AuthEndPoints {
  LOGIN = '/auth/token',
  NewPassword = '/ApplicationUser/NewPassword',
  EmployeeAccountConfirmation = '/ApplicationUser/EmployeeAccountConfirmation',
  ForgotPassword = '/ApplicationUser/ForgotPassword',
  ResetPassword = '/ApplicationUser/ResetPassword',
  EmailConfirmation = '/ApplicationUser/EmailConfirmation',
  IsEmailConfirmed = '/ApplicationUser/IsEmailConfirmed',
}

export enum ApiEndPoints {
  AddEmployee = '/Employee/AddEmployee',
  UpdateEmployee = '/Employee/UpdateEmployee',
  UpdateEmployeeEmail = '/Employee/UpdateEmployeeEmail',
  EmployeeList = '/Employee/EmployeeList',
  EmployeeNameList = '/Employee/EmployeeNameList',
  EmployeeNameListByDate = '/Employee/EmployeeNameListByDate',
  GetInvitationInfo = '/Employee/GetInvitationInfo',
  GetWeekTimeChart = '/Employee/GetWeekTimeChart',
  DeleteEmployee = '/Employee/DeleteEmployee',
  ActivateDeactivateEmployee = '/Employee/ActivateDeactivateEmployee',
  GetEmployeeDetails = '/Employee/GetEmployeeDetails',
  FillWorkShiftsAutomatically = '/Calender/FillWorkShiftsAutomatically',
  AddEvent = '/Calender/AddEvent',
  UpdateEvent = '/Calender/UpdateEvent',
  GetWorkShiftDetails = '/Calender/GetWorkShiftDetails',
  GetEvents = '/Calender/GetEvents',
  GetEmployeeEvents = '/Calender/GetEmployeeEvents',
  GetTimelineEvents = '/Calender/GetTimelineEvents',
  GetEventsAndScheduleRequirementStatus = '/Calender/GetEventsAndScheduleRequirementStatus',
  PublishWorkPlan = '/Calender/PublishWorkPlan',
  DeleteWorkPlan = '/Calender/DeleteWorkPlan',
  DeleteEvent = '/Calender/DeleteEvent',
  GetCalendarWeekList = '/Calender/GetCalendarWeekList',
  GetEmployeeAvailability = '/Calender/GetEmployeeAvailability',
  WorkShiftListRequest = '/Calender/WorkShiftListRequest',
  GetScheduleRequirementEvents = '/ScheduleRequirement/GetTimelineEvents',
  AddAbsence = '/Absence/AddAbsence',
  DeleteAbsence = '/Absence/DeleteAbsence',
  AddAbsenceForMe = '/Absence/AddAbsenceForMe',
  UpdateAbsence = '/Absence/UpdateAbsence',
  GetAbsence = '/Absence/GetAbsence',
  GetAbsenceCalender = '/Absence/GetAbsenceCalender',
  GetEmployeeAbsence = '/Absence/GetEmployeeAbsence',
  GetAbsenceRequests = '/Absence/GetAbsenceRequests',
  UpdateAbsenceRequest = '/Absence/UpdateAbsenceRequest',
  GetHolidaysForEmployeeChart = '/Absence/GetHolidaysForEmployeeChart',
  GetConflictsWithWorkingShift = '/Absence/GetConflictsWithWorkingShift',
  AddOpenShiftByAbsenceIdCommand = '/Absence/AddOpenShiftByAbsenceIdCommand',
  GetAbsenceSummary = '/Absence/GetAbsenceSummary',
  AddPlanTemplate = '/PlanTemplate/AddPlanTemplate',
  UpdateTemplateImage = '/PlanTemplate/UpdateTemplateImage',
  ApplyPlanTemplate = '/PlanTemplate/ApplyTemplate',
  GetTemplateList = '/PlanTemplate/GetTemplateList',
  DeletePlanTemplate = '/PlanTemplate/DeletePlanTemplate',
  GetCompanySettings = '/Company/GetCompanySettings',
  UpdateCompanySettings = '/Company/UpdateCompanySettings',

  GetShiftsForEmployee = '/OpenShift/GetShiftsForEmployee',
  GetOpenShiftListForAdmin = '/OpenShift/GetOpenShiftListForAdmin',
  AddEmployeeToOpenShift = '/OpenShift/AddEmployeeToOpenShift',
  AdoptOpenShiftInWorkShift = '/OpenShift/AdoptOpenShiftInWorkShift',
  GetOpenShiftDetails = '/OpenShift/GetOpenShiftDetails',
  DeleteOpenShift = '/OpenShift/DeleteOpenShift',
  AddOpenShift = '/OpenShift/AddOpenShift',
  UpdateOpenShift = '/OpenShift/UpdateOpenShift',

  AddAccessToken = '/ClientDevice/AddAccessToken',

  AddDepartment = '/Department/AddDepartment',
  UpdateDepartment = '/Department/UpdateDepartment',
  DeleteDepartment = '/Department/DeleteDepartment',
  DepartmentList = '/Department/DepartmentList',
  SkillList = '/Skill/SkillList',
  DeleteSkill = '/Skill/DeleteSkill',
  EmployeeSkillList = '/Skill/EmployeeSkillList',
  UpdateSkillWeighting = '/Skill/UpdateSkillWeighting',
  AddSkill = '/Skill/AddSkill',
  UpdateSkill = '/Skill/UpdateSkill',
  IconList = '/Icon/GetIcons',
  GetIconTypes = '/Icon/GetIconTypes',
  DepartmentListWithEmployeeCount = '/Department/DepartmentListWithEmployeeCount',
  DepartmentEmployeeList = '/Department/DepartmentEmployeeList',

  DivisionList = '/Division/DivisionList',
  DivisionWithDepartmentsList = '/Division/DivisionWithDepartmentsList',

  AddDailyAvailability = '/Availability/AddDailyAvailability',
  AddDailyAvailabilityAdmin = '/Availability/AddDailyAvailabilityAdmin',
  UpdateDailyAvailability = '/Availability/UpdateDailyAvailability',
  UpdateDailyAvailabilityAdmin = '/Availability/UpdateDailyAvailabilityAdmin',
  AddAvailability = '/Availability/AddAvailability',
  DeleteAvailability = '/Availability/DeleteAvailability',
  DeleteAvailabilityAdmin = '/Availability/DeleteAvailabilityAdmin',
  GetAvailability = '/Availability/GetAvailability',
  GetAvailabilityPositionId = '/Availability/GetAvailabilityPositionId',

  GetAvailabilityTimelineEvents = '/Availability/GetAvailabilityTimelineEvents',
  ImageUpload = '/Images/Upload',
  ImageDelete = '/Images/Delete',
  AddScheduleRequirement = '/ScheduleRequirement/AddEvent',
  DeleteScheduleRequirement = '/ScheduleRequirement/DeleteScheduleRequirement',
  UpdateScheduleRequirement = '/ScheduleRequirement/UpdateEvent',
  PutShiftForSwap = '/ShiftSwap/PutShiftForSwap',
  AddTargetedRequestToSwapRequest = '/ShiftSwap/AddTargetedRequestToSwapRequest',
  GetPotentialShiftSwappersQuery = '/ShiftSwap/GetPotentialShiftSwappersQuery',
  GetEmployeeSwapShiftsList = '/ShiftSwap/GetEmployeeSwapShiftsList',
  GetShiftSwapStatusQuery = '/ShiftSwap/GetShiftSwapStatusQuery',
  GetAllEmplyeesSwappingProcessQuery = '/ShiftSwap/GetAllEmplyeesSwappingProcessQuery',
  GetShiftsForSwapQuery = '/ShiftSwap/GetShiftsForSwapQuery',
  GetEmployeeWorkShiftsForSwapingQuery = '/ShiftSwap/GetEmployeeWorkShiftsForSwapingQuery',
  AddProposedOptionToSwapRequestCommand = '/ShiftSwap/AddProposedOptionToSwapRequestCommand',
  GetShiftsSwapCandidateQuery = '/ShiftSwap/GetShiftsSwapCandidateQuery',
  SwapEmployeeBetweenShiftsCommand = '/ShiftSwap/SwapEmployeeBetweenShiftsCommand',
  EmployeeAcceptShiftSwapCommand = '/ShiftSwap/EmployeeAcceptShiftSwapCommand',
  SwapEmployeeBetweenTargetedShiftsCommand = '/ShiftSwap/SwapEmployeeBetweenTargetedShiftsCommand',

  AddClockEvent = '/TimeRecording/AddClockEvent',
  AddClockEventAdmin = '/TimeRecording/AddClockEventAdmin',
  UpdateClockEvent = '/TimeRecording/UpdateClockEvent',
  DeleteClockEvent = '/TimeRecording/DeleteClockEvent',
  DeleteClockEventAdmin = '/TimeRecording/DeleteClockEventAdmin',
  GetAllClockEvents = '/TimeRecording/GetAllClockEvents',
  GetEmployeeLastStatus = '/TimeRecording/GetEmployeeLastStatus',
  GetEmployeeStatus = '/TimeRecording/GetEmployeeStatus',
  GetTimeRecordingTimelineForCompany = '/TimeRecording/GetTimeRecordingTimelineForCompany',
  GetDayWeekMonthStatsQuery = '/TimeRecording/GetDayWeekMonthStatsQuery',
  GetAllClockEventsForDay = '/TimeRecording/GetAllClockEventsForDay',
  GetClockEventsForMultipleDays = '/TimeRecording/GetClockEventsForMultipleDays',
  GetLiveStatus = '/TimeRecording/GetLiveStatus',

  GetEmployeeWorkHoursReportQuery = '/Reports/GetEmployeeWorkHoursReportQuery',
  GetTotalWorkHoursReportQuery = '/Reports/GetTotalWorkHoursReportQuery',
  GetPieChartQuery = '/Reports/GetPieChartQuery',
  GetEmployeeWorkHoursReportPdfQuery = '/Reports/GetEmployeeWorkHoursReportPdfQuery',

  GetCompanySubscriptionDetailsQuery = '/Subscription/GetCompanySubscriptionDetailsQuery',
  GetAvailableSubscriptionsQuery = '/Subscription/GetAvailableSubscriptionsQuery',

  CreateCheckoutSessionCommand = '/Payment/CreateCheckoutSessionCommand',
  CreateCustomerPortalSession = '/Payment/CreateCustomerPortalSession',
  ChangeSubscriptionPlanCommand = '/Payment/ChangeSubscriptionPlanCommand',
}

export enum UserRoles {
  Manager = 1,
  Employee = 2,
  TeamLeader = 3,
}

export enum EventType {
  Nothing = 0,
  WorkShift = 1,
  Absence = 2,
  OpenShift = 3,
}

export enum WorkShiftStatus {
  Draft = 1,
  Published = 2,
  AcceptedFromEmployee = 3,
  RejectedFromEmployee = 4,
  Closed = 5,
  SwapRequested = 6,
}

export enum LoginErrorType {
  None = 0,
  UserNotFound = 1,
  PasswordIncorrect = 2,
  UserLockedOut = 3,
  UserDeactivated = 4,
  RefreshTokenExpired = 5,
}

export enum AbsenceType {
  Nothing = 0,
  Vacation = 1,
  Sick = 2,
  NotAvailable = 3,
}

export enum SortShiftBy {
  Nothing = 0,
  ByShiftStart = 1,
  ByAvailableTime = 2,
  SkillTime = 3,
  Status = 4,
}

export enum ShiftSwapStatus {
  Created = 1,
  AcceptedFromEmployee = 2,
  AcceptedFromAdmin = 3,
  Rejected = 4,
  Closed = 5,
}

export enum ClockEventType {
  Nothing = 0,
  ClockIn = 1,
  ClockOut = 2,
  BreakStart = 3,
  BreakEnd = 4,
}

export enum JobType {
  Nothing = 0,
  FullTime = 1,
  PartTime = 2,
  MiniJob = 3,
}
