import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

import { Subscription } from 'rxjs';
import { ClockEventType } from 'src/app/core/services/const';
import { ClockEventOneDayDto } from '../../model/clock-event-dto.model';

@Component({
  selector: 'app-time-recording-accordion',
  templateUrl: './time-recording-accordion.component.html',
  styleUrls: ['./time-recording-accordion.component.css'],
})
export class TimeRecordingAccordionComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() eventListAccordion: ClockEventOneDayDto[];
  @Input() ClockEventType = ClockEventType;
  @Output() clockEvent = new EventEmitter<boolean>();

  expandedIndex = -1;
  private subscriptions: Subscription[] = [];
  formattedEventDates: string[] = [];
  formattedDurations: string[] = [];
  formattedTimeRanges: string[] = [];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateFormattedData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventListAccordion) {
      this.updateFormattedData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateFormattedData(): void {
    if (this.eventListAccordion) {
      this.formattedEventDates = this.eventListAccordion.map((event) =>
        this.getEventDate(event.EventDate)
      );
      this.formattedDurations = this.eventListAccordion.map((event) =>
        this.getDurationFromMinutes(event.TotalWorkTimeDay)
      );
      this.formattedTimeRanges = this.eventListAccordion.map((event) =>
        this.showTimeRange(event)
      );
    }
  }

  private getEventDate(event): string {
    const eventDate = new Date(event);
    const today = new Date();
    if (eventDate.toDateString() === today.toDateString()) {
      return this.translate.instant('TODAY');
    }
    return eventDate.toLocaleDateString(navigator.language, {
      month: '2-digit',
      day: '2-digit',
    });
  }

  private getDurationFromMinutes(minutes: number): string {
    const totalWorkTime = dayjs.duration(minutes, 'minutes');
    return this.formatDuration(totalWorkTime);
  }

  private formatDuration(duration: duration.Duration): string {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }

  private showTimeRange(event: ClockEventOneDayDto): string {
    const firstEvent = new Date(event.FristClockIn);
    const lastEvent = new Date(event.LastClockOut);

    const startTime = firstEvent.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
    let endTime = lastEvent.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });

    if (
      new Date(event.LastClockOut).toDateString() ===
        new Date().toDateString() &&
      event.ClockEvents &&
      event.ClockEvents.length > 0 &&
      event.ClockEvents[event.ClockEvents.length - 1].EventEndId === 0
    ) {
      endTime = this.translate.instant('NOW');
    }

    return `${startTime} - ${endTime}`;
  }

  panelOpened(index: number) {
    this.expandedIndex = index;
  }

  panelClosed(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = -1;
    }
  }

  handleClockEvent(event) {
    this.clockEvent.emit(event);
  }
}
