<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'ADD NEW DEPARTMENT'| translate}}</h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(form)">
        <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModel.Id" />
        <div style="padding-bottom: 10px;" class="row">
          <div class="form-group col-md-12 mb-6">
            <label style="padding-bottom: 10px;" for="InputFirstName">{{'DEPARTMENT NAME'| translate}}</label>
            <input autocomplete="off" type="text" class="form-control" id="Name" aria-describedby="emailHelp"
              #FirstName="ngModel" placeholder="{{'ENTER DEPARTMENT NAME'| translate}}" name="Name"
              [(ngModel)]="formModel.Name" required>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button" data-dismiss="modal">
        {{'Close'| translate}}</button>
      <button class="btn btn-primary active" form="addEmployee" type="submit" [disabled]="form.invalid">
        {{'Save'| translate}}</button>
    </div>
  </div>
</div>