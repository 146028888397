<div class="container content-center">
    <div class="row">
        <div class="col-sm-8 col-md-6 mx-auto">
            <div class="card shadow-lg">
                <div class="card-body">
                    <img class="center" style="padding-top: 30px; width: 200px; margin-bottom: 30px" src="../../../../../assets/img/Logo.PNG" alt="Logo" />
                    <form [formGroup]="registerForm" autocomplete="off" novalidate (ngSubmit)="registerUser()">
                        <div class="row">
                            <div class="form-group col-md-6 mb-4">
                                <label for="firstName" class="form-label">
                  {{ "First Name" | translate }}
                </label>
                                <input type="text" id="firstName" formControlName="firstName" class="form-control" autocomplete="given-name" />
                            </div>
                            <div class="form-group col-md-6 mb-4">
                                <label for="lastName" class="form-label">
                  {{ "Last Name" | translate }}
                </label>
                                <input type="text" id="lastName" formControlName="lastName" class="form-control" autocomplete="family-name" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6 mb-4">
                                <label for="email" class="form-label">
                  {{ "Email" | translate }}
                </label>
                                <input type="email" id="email" formControlName="email" class="form-control" autocomplete="email" />
                                <div *ngIf="validateControl('email')" class="text-danger">
                                    <em *ngIf="hasError('email', 'email')">
                    {{ "PLEASE PROVIDE A VALID EMAIL" | translate }}
                  </em>
                                    <em *ngIf="hasError('email', 'required')">
                    {{ "EMAIL IS REQUIRED" | translate }}
                  </em>
                                </div>
                            </div>
                            <div class="form-group col-md-6 mb-4">
                                <label for="companyName" class="form-label">
                  {{ "Company" | translate }}
                </label>
                                <input type="text" id="companyName" formControlName="companyName" class="form-control" autocomplete="organization" />
                                <div *ngIf="validateControl('companyName')" class="text-danger">
                                    <em *ngIf="hasError('companyName', 'required')">
                    {{ "COMPANY NAME IS REQUIRED" | translate }}
                  </em>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6 mb-4">
                                <label for="password" class="form-label">
                  {{ "Password" | translate }}
                </label>
                                <div class="input-group">
                                    <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password" class="form-control" autocomplete="new-password" placeholder="{{ 'Enter your password' | translate }}" />
                                    <span class="input-group-text password-toggle" (click)="toggleShowPassword()" role="button">
                    <i
                      class="bi"
                      [ngClass]="showPassword ? 'bi-eye-slash' : 'bi-eye'"
                    ></i>
                  </span>
                                </div>
                                <div *ngIf="validateControl('password')" class="text-danger">
                                    <em *ngIf="hasError('password', 'passwordStrength')">
                    {{
                      "PASSWORD MUST CONTAIN UPPERCASE LOWERCASE NUMBER AND SPECIAL CHARACTER"
                        | translate
                    }}
                  </em>
                                    <em *ngIf="hasError('password', 'minlength')">
                    {{
                      "PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG" | translate
                    }}
                  </em>
                                </div>
                            </div>

                            <div class="form-group col-md-6 mb-4">
                                <label for="confirmPassword" class="form-label">
                  {{ "Confirm Password" | translate }}
                </label>
                                <div class="input-group">
                                    <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" formControlName="confirmPassword" class="form-control" autocomplete="new-password" placeholder="{{ 'Confirm your password' | translate }}" />
                                    <span class="input-group-text password-toggle" (click)="toggleShowConfirmPassword()" role="button">
                    <i
                      [ngClass]="
                        showConfirmPassword ? 'bi bi-eye-slash' : 'bi bi-eye'
                      "
                    ></i>
                  </span>
                                </div>
                                <div *ngIf="validateControl('confirmPassword')" class="text-danger">
                                    <em *ngIf="hasError('confirmPassword', 'required')">
                    {{ "CONFIRMATION IS REQUIRED" | translate }}
                  </em>
                                    <em *ngIf="hasError('confirmPassword', 'mustMatch')">
                    {{ "PASSWORDS MUST MATCH" | translate }}
                  </em>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3 row">
                            <div class="row subscription-container justify-content-center" *ngIf="availableSubscriptions$ | async as subscriptions">
                                <div *ngFor="let subscription  of subscriptions" class="col-md-4 subscription-card" [ngClass]="{
                    'selected-subscription':
                      selectedSubscription?.Id === subscription.Id
                  }" (click)="selectSubscription(subscription)" role="button">
                                    <h5 class="subscription-name">
                                        {{ subscription.SubscriptionPlanName }}
                                    </h5>
                                    <p class="subscription-price">
                                        <span class="original-price">
                      {{ subscription.PricePerUserPerMonth }}€ / Monat
                    </span>
                                        <span class="discounted-price">3 Monate kostenlos</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3 row">
                            <div class="d-grid">
                                <button type="submit" class="btn btn-primary btn-login bi bi-box-arrow-in-right fa-lg" [disabled]="!registerForm.valid">
                  {{ "REGISTER" | translate }}
                </button>
                                <div class="text-center mt-3">
                                    <a class="small" [routerLink]="['/login']">
                    {{ "LOG IN" | translate }}
                  </a>
                                </div>
                                <br />
                                <p class="text-center small">
                                    Beim Klicken auf <b>Registrieren</b> stimmen Sie unseren
                                    <a href="https://fixplaner.com/datenschutz" target="_blank">
                    Nutzungsbedingungen
                  </a> und unserer
                                    <a href="https://fixplaner.com/datenschutz" target="_blank">
                    Datenschutzerklärung
                  </a> zu.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>