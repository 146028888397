<div class="modal-dialog modal-lg" role="document" style="max-width: 600px">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{heder}}</h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(form)">
        <input type="hidden" #StarTime="ngModel" name="StarTime" [(ngModel)]="formModel.StarTime" />
        <input type="hidden" #EndTime="ngModel" name="EndTime" [(ngModel)]="formModel.EndTime" />
        <div class="row">
          <div class="row">
            <div *ngIf="employeeAvailability?.AbsenceType == 2" class="alert alert-warning" role="alert">
              {{employeeName}} {{'THE EMPLOYEE IS ON SICK LEAVE THAT DAY' | translate}}
            </div>
            <div *ngIf="employeeAvailability?.AbsenceType == 1" class="alert alert-warning" role="alert">
              {{employeeName}} {{'THE EMPLOYEE HAS VACATION ON THIS DAY' | translate}}
            </div>
            <div *ngIf="employeeAvailability?.AvailableStart && employeeAvailability?.AbsenceType == 0"
              class="alert alert-info" role="alert">
              {{employeeName}} {{'IS AVAILABLE FROM' | translate}}
              {{convertDateToString(employeeAvailability.AvailableStart)}} {{'TO' | translate}}
              {{convertDateToString(employeeAvailability.AvailableEnd)}}.
            </div>
          </div>
          <div class="form-group col-md-4 mb-3">
            <mat-label>{{'START OF WORK' | translate}}</mat-label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="star-time-icon" style="height: 40px;"><i
                    class="fa-light fa-arrow-right-to-bracket"></i></span>
              </div>
              <input #Start="ngModel" name="Start" [min]="minTime" id="Start" class="form-control" [format]="24"
                [ngxMatTimepicker]="pickerStart" [(ngModel)]="hour.StarTime" required inputmode='none'
                (ngModelChange)="onTimeChange($event)" aria-describedby="star-time-icon">
              <ngx-mat-timepicker #pickerStart [minutesGap]="5" (timeSet)="onTimeset($event)"></ngx-mat-timepicker>
            </div>
          </div>
          <div class="form-group col-md-4 mb-3">
            <mat-label>{{'END OF WORK' | translate}}</mat-label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="end-time-icon" style="height: 40px;"><i
                    class="fa-light fa-arrow-right-from-bracket"></i></span>
              </div>
              <input #End="ngModel" name="End" [min]="minEndTime" id="End" class="form-control" [format]="24"
                [ngxMatTimepicker]="pickerEnd" [(ngModel)]="hour.EndTime" required inputmode='none'
                (ngModelChange)="onTimeChange($event)" aria-describedby="end-time-icon">
              <ngx-mat-timepicker #pickerEnd [minutesGap]="5"></ngx-mat-timepicker>
            </div>
          </div>
          <div class="form-group col-md-4 mb-3">
            <mat-label>Pause</mat-label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="break-time-icon" style="height: 40px;"><i
                    class="fa-light fa-mug-hot"></i></span>
              </div>
              <input type="text" id="Break" name="Break" [matAutocomplete]="auto" class="form-control"
                [(ngModel)]="formModel.Break" (input)="onInputChange($event)" aria-describedby="break-time-icon">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let suggestion of filteredSuggestions" [value]="suggestion"
                  (onSelectionChange)="selectSuggestion($event, suggestion)">
                  {{ suggestion }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="timeInputControl.hasError('pattern')">
                Die Eingabe darf nur Zahlen zwischen 0 und 120 sein.
              </mat-error>
            </div>
          </div>
          <div
            *ngIf="Start.value && End.value && End.value != '00:00' && Start.value != '00:00' && Start.value > End.value"
            class="alert alert-danger" role="alert">
            {{'START TIME CANNOT BE GREATER THAN END TIME' | translate}}
          </div>
          <div *ngIf="isOverDailyWorkingHours()" class="alert alert-danger" role="alert">
            {{dailyWorkingHoursText}}
          </div>
          <div *ngIf="isBreakTimeInsufficient()" class="alert alert-danger" role="alert">
            {{requiredBreakTimeText}}
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label>{{'Department' | translate}}</label>
            <mat-select class="form-control" name="DepartementId" placeholder="Department"
              [(ngModel)]="formModel.DepartementId" (selectionChange)="onDepartmentChange($event)"
              [disabled]="employeeSelectionDisabled">
              <mat-option *ngFor="let department of departmentList" [value]="department.Id">{{department.Name}}
              </mat-option>
            </mat-select>
            <input type="hidden" name="DepartementID" [ngModel]="formModel.DepartementId" />
          </div>
          <div class="form-group col-md-6 mb-4">
            <label>{{'QUALIFICATION' | translate}}</label>
            <mat-select class="form-control" name="SkillId" placeholder="Skill" [(ngModel)]="formModel.SkillId"
              (selectionChange)="onIconChange($event)">
              <mat-select-trigger>
                <span *ngIf="selectedValue">
                  <i [ngClass]="selectedValue.IconCode + ' fa-lg'"></i>
                  {{selectedValue.Name}}
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let skill of skillList; let i = index" [value]="skill.Id" [attr.selected]="!i">
                <i [ngClass]="skill.IconCode + ' fa-lg'"></i> {{skill.Name}}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div *ngIf="formModel.OpenShift" class="row">
          <div class="form-group col-6 mb-4">
            <label for="RequiredStaff">{{'Required Staff' | translate}}</label>
            <input type="number" class="form-control form-control-user" id="RequiredStaff"
              placeholder="{{'Required Staff' | translate}}" #Email="ngModel" name="RequiredStaff"
              [(ngModel)]="formModel.RequiredStaff">
          </div>
          <div class="form-group col-6 mb-4">
            <label class="form-label">{{'DIRECTLY ASSIGN EMPLOYEE' | translate}}</label>
            <div class="form-group">
              <mat-slide-toggle color="primary" [checked]="assignEmployeeForOpenShift" name="AllDay"
                (change)="updateAssignEmployeeForOpenShift()" [(ngModel)]="assignEmployeeForOpenShift">{{
                assignEmployeeForOpenShift ? ('YES' | translate) : ('NO' | translate) }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="!formModel.OpenShift || assignEmployeeForOpenShift" class="form-group col-md-6 mb-4">
            <label>{{'Employee' | translate}}</label>
            <mat-select class="form-control" #EmployeeId name="EmployeeId" [(ngModel)]="formModel.EmployeeId"
              [disabled]="employeeSelectionDisabled" placeholder="{{'EMPLOYEE NAME' | translate}}"
              (ngModelChange)="onEmployeeIdChange(formModel)" disableOptionCentering>
              <mat-option>
                <ngx-mat-select-search [formControl]="categoryFilterCtrl"></ngx-mat-select-search>
              </mat-option>
              <ng-container *ngFor="let category of filteredCategory | async">
                <mat-option [value]="category.Id" [disabled]="category.AbsenceType === 1 || category.AbsenceType === 2">
                  <i class="fal"
                    [ngClass]="{'fa-solid fa-clock fa-lg': category.Availability,'bi bi-emoji-sunglasses fa-lg': category.AbsenceType === 1, 'bi bi-heart-pulse fa-lg':category.AbsenceType === 2, 'bi bi-person-check-fill fa-lg':(category.AbsenceType === 0 || category.AbsenceType === null) && !category.Availability}"></i>
                  &nbsp; {{category.Name}}
                </mat-option>
              </ng-container>
            </mat-select>
            <input type="hidden" name="EmployeeID" [ngModel]="formModel.EmployeeId" />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button" data-dismiss="modal">{{'Close' |
        translate}}</button>
      <button class="btn btn-primary" form="addEmployee" type="submit" [disabled]="form.invalid ||
         employeeAvailability?.AbsenceType === 1 ||
          employeeAvailability?.AbsenceType === 2 ||
           form.value.Start === '00:00' ||
            form.value.End === '00:00' ||
             (Start.value && End.value && End.value !== '00:00' && Start.value !== '00:00' && Start.value > End.value) ||
              formModel.SkillId === 0 ||
              formModel.DepartementId === 0 ||
              (formModel.EmployeeId === 0 && !formModel.OpenShift)||
              (!formModel.RequiredStaff && formModel.OpenShift) ||
              (formModel.EmployeeId === 0 && assignEmployeeForOpenShift) ||
              isOverDailyWorkingHours() ||
              isBreakTimeInsufficient()">{{'Save' | translate}}</button>
    </div>
  </div>
</div>