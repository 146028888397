import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { ReportPaginationService } from '../component/report-list/report-pagination.service';
import { EmployeeWorkHoursDto } from '../model/employee-work-hours-dto';
import { EmployeeWorkHoursReportDto } from '../model/employee-work-hours.model';
import { PieChartDto } from '../model/pie-chart-dto.model';
import { TotalWorkHoursReportDto } from '../model/total-work-hours-report-dto.model';
@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(
    private httpClient: HttpService,
    public paginationService: ReportPaginationService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  private employeeListSource: BehaviorSubject<EmployeeWorkHoursDto[]> =
    new BehaviorSubject([]);
  employeeList = this.employeeListSource.asObservable();

  private totalWorkHoursReportListSource: BehaviorSubject<TotalWorkHoursReportDto> =
    new BehaviorSubject(new TotalWorkHoursReportDto());
  totalWorkHoursReportList = this.totalWorkHoursReportListSource.asObservable();

  private getPieChartQuerySource: BehaviorSubject<PieChartDto> =
    new BehaviorSubject(new PieChartDto());
  getPieChartData = this.getPieChartQuerySource.asObservable();

  getEmployeeWorkHoursReport(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();

    const postObject = {
      Pagination: this.paginationService.paginationModel,
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
    };

    this.httpClient
      .requestCall(
        ApiEndPoints.GetEmployeeWorkHoursReportQuery,
        ApiMethod.POST,
        postObject
      )
      .subscribe((res) => {
        const employeeList = res as EmployeeWorkHoursReportDto;
        if (employeeList) {
          employeeList.DataList.forEach((employee) => {
            employee.totalWorkTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.TotalWorkMinutes
              );
            employee.totalOvertimeTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.TotalOvertimeMinutes
              );
          });
          this.employeeListSource.next(employeeList.DataList);
          this.paginationService.setPaginationModel(employeeList.Pagination);
        }
      });
  }

  getTotalWorkHoursReportQuery(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();

    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
    };

    this.httpClient
      .requestCall(
        ApiEndPoints.GetTotalWorkHoursReportQuery,
        ApiMethod.POST,
        postObject
      )
      .subscribe((res) => {
        const workReport = res as TotalWorkHoursReportDto;
        if (workReport) {
          workReport.totalWorkTime =
            this.convertMinutesToLocalizedHoursAndMinutes(
              workReport.TotalWorkMinutes
            );
          workReport.totalOvertimeTime =
            this.convertMinutesToLocalizedHoursAndMinutes(
              workReport.TotalOvertimeMinutes
            );

          this.totalWorkHoursReportListSource.next(workReport);
        }
      });
  }

  getPieChartQuery(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();

    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
    };

    this.httpClient
      .requestCall(ApiEndPoints.GetPieChartQuery, ApiMethod.POST, postObject)
      .subscribe((res) => {
        const workReport = res as PieChartDto;
        if (workReport) {
          this.getPieChartQuerySource.next(workReport);
        }
      });
  }

  GetEmployeeWorkHoursReportPdfQuery(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();

    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
      Language: this.translateService.currentLang,
    };

    this.httpClient
      .requestCall(
        ApiEndPoints.GetEmployeeWorkHoursReportPdfQuery,
        ApiMethod.POST,
        postObject,
        undefined,
        'blob'
      )
      .subscribe(
        (res: Blob) => {
          this.downloadFile(res);
        },
        (error) => {
          console.error('Error downloading the file', error);
        }
      );
  }

  private downloadFile(data: Blob): void {
    // Versucht, den Blob als JSON zu lesen
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const jsonResponse = JSON.parse(reader.result as string);

        // Überprüfe, ob die Antwort ein gültiges base64-kodiertes PDF ist
        if (jsonResponse && typeof jsonResponse === 'string') {
          this.downloadPdfFromBase64(jsonResponse, 'report.pdf');
        } else {
          console.error(
            'Die empfangenen Daten enthalten kein gültiges base64-kodiertes PDF.'
          );
        }
      } catch (error) {
        console.error('Die empfangenen Daten sind kein gültiges JSON.', error);
      }
    };

    reader.onerror = () => {
      console.error('Fehler beim Lesen des Blobs.');
    };

    reader.readAsText(data);
  }

  private downloadPdfFromBase64(base64Data: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = fileName;
    link.click();
  }

  private convertMinutesToLocalizedHoursAndMinutes(minutes: number): string {
    if (isNaN(minutes) || minutes === null || minutes === undefined) {
      return '0h 0m';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const language = navigator.language || 'en-US';
    let hoursLabel = 'h';
    let minutesLabel = 'm';

    if (language.startsWith('de')) {
      hoursLabel = 'Std';
      minutesLabel = 'Min';
    }

    const formatter = new Intl.NumberFormat(language, {
      minimumIntegerDigits: 1,
    });

    return `${formatter.format(hours)}${hoursLabel} ${formatter.format(
      remainingMinutes
    )}${minutesLabel}`;
  }
}
