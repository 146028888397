import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';

import { animate, keyframes, transition, trigger } from '@angular/animations';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg } from '@fullcalendar/core';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import tippy, {
  followCursor,
  hideAll,
  inlinePositioning,
  roundArrow,
} from 'tippy.js';
import { DailyAvailability } from '../../model/daily-availability';
import { AvailabilityService } from '../../services/availability.service';
import { AddAvailabilityComponent } from '../add-availability/add-availability.component';
import * as kf from './keyframes';

@Component({
  selector: 'app-availability-planner',
  templateUrl: './availability-planner.component.html',
  styleUrls: ['./availability-planner.component.css'],
  animations: [
    trigger('cardAnimator', [
      transition('* => wobble', animate(1000, keyframes(kf.wobble))),
      transition('* => swing', animate(1000, keyframes(kf.swing))),
      transition('* => jello', animate(1000, keyframes(kf.jello))),
      transition(
        '* => zoomOutRight',
        animate(1000, keyframes(kf.zoomOutRight))
      ),
      transition('* => slideOutLeft', animate(300, keyframes(kf.slideOutLeft))),
      transition(
        '* => slideOutRight',
        animate(300, keyframes(kf.slideOutRight))
      ),
      transition(
        '* => rotateOutUpRight',
        animate(1000, keyframes(kf.rotateOutUpRight))
      ),
      transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
    ]),
  ],
})
export class AvailabilityPlannerComponent implements OnInit {
  public innerWidth: any;
  animationState: string;
  currentCalendarData: any;
  calendarApi: any;

  calendarOptions: CalendarOptions = {
    plugins: [timeGrigPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: this.companyService.CompanySettings.CalendarOptions.FirstDay,
    height: 'auto',
    initialView: 'settimana',
    eventOverlap: false,
    slotEventOverlap: false,
    weekNumbers: true,
    slotMinTime:
      this.companyService.CompanySettings.CalendarOptions
        .BusinessHoursStartTime,
    slotMaxTime:
      this.companyService.CompanySettings.CalendarOptions.BusinessHoursEndTime,
    headerToolbar: {
      start: '',
      center: '',
      end: 'addAvailabilityButton',
    },
    views: {
      settimana: {
        type: 'timeGridWeek',
        weekday: 'long',
        dayHeaderFormat: { weekday: 'short' },
        // hidde weeke number
        weekNumberCalculation: 'ISO',
        weekNumberFormat: (weekNumber) => {
          return '';
        },
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        },
        slotLabelInterval: '01:00:00',
        slotDuration: '00:30:00',
        slotLabelClassNames: 'slot-label-availability',
        dayHeaderClassNames: 'day-header-availability',
      },
    },
    allDaySlot: false,
    businessHours: {
      startTime: '08:00', // a start time (10am in this example)
      endTime: '22:30', // an end time (6pm in this example)

      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      // days of week. an array of zero-based day of week integers (0=Sunday)
      // (Monday-Thursday in this example)
    },
    displayEventTime: true,
    weekends: true,
    editable: false,
    selectMirror: true,
    dayMaxEvents: true,
    selectable: false,
    eventStartEditable: false,
    customButtons: {
      addAvailabilityButton: {
        text: this.translate.instant('ADD'),
        click: () => this.addAvailability(),
      },
    },
    select: this.handleDateSelect.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    eventContent: this.handleEventContent.bind(this),
    eventConstraint: 'lesson-available',
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private service: AvailabilityService,
    private dialog: MatDialog,
    private zone: NgZone,
    private translate: TranslateService,
    private companyService: CompanySettingsService,
    private el: ElementRef
  ) {
    Window['AvailabilityPlannerComponent'] = this;
  }
  private subscriptionAvailabilityEventList: Subscription;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  HTMLElement: HTMLImageElement;

  ngOnInit(): void {
    this.subscriptionAvailabilityEventList =
      this.service.availabilityEventList.subscribe((list) => {
        if (this.calendarComponent) {
          const calendar = this.calendarComponent.getApi();
          if (
            calendar &&
            JSON.stringify(this.calendarOptions.events) !== JSON.stringify(list)
          ) {
            calendar.removeAllEvents();
          }
        }

        this.calendarOptions.events = list;
        if (this.HTMLElement?.textContent) {
          this.HTMLElement.textContent =
            '     ' + this.translate.instant('MY AVAILABILITY PLAN') + ' ';
        }
      });
    this.service.getAvailabilityEvent(false);
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.HTMLElement =
      this.el.nativeElement.querySelector('.fc-header-toolbar');
  }

  resetAnimationState(): void {
    this.animationState = '';
  }

  handleEventContent(event): any {
    return {
      html:
        '<div style="text-shadow: 1px 1px 2px #000000;font-size:15px; text-align: center;vertical-align: middle; margin-bottom: 5px;" class="fc-event-time">' +
        dayjs(event.event.start).format('HH:mm') +
        '</div>' +
        '<div style="text-shadow: 3px 3px 5px #000000;font-size:18px; text-align: center;vertical-align: middle;" class="fc-event-time">' +
        ' <i class="bi bi-arrow-down fa-2x"></i> ' +
        '</div>' +
        '<div style="text-shadow: 2px 2px 4px #000000;font-size:15px; text-align: center;vertical-align: middle;" class="fc-event-time">' +
        dayjs(event.event.end).format('HH:mm') +
        '</div>',
    };
  }

  handleDateSelect(selectInfo: DateSelectArg): void {
    this.service.addAvailability({
      StarTime: selectInfo.startStr,
      EndTime: selectInfo.endStr,
      EmployeeId: 0,
      Id: 0,
    });
  }

  addAvailability(eventId = null): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';

    if (eventId) {
      const shift = (this.calendarOptions.events as []).find(
        (x: any) => x.eventId === eventId
      ) as any;
      dialogConfig.data = {
        Start: shift.startTime,
        End: shift.endTime,
        Id: shift.id,
        Weekday: shift.dayOfTheWeek ?? '0',
        isWeekendVisible: true,
      } as DailyAvailability;
    }

    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(AddAvailabilityComponent, dialogConfig);
    });
  }

  deleteEvent(id): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to Delete this Availability Slot';

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.currentCalendarData) {
          const calendar = this.calendarComponent.getApi();
          this.currentCalendarData = calendar.getCurrentData();
        }
        this.service.deleteAvailability(id);
      }
      this.dialogRef = null;
    });
  }

  handleEventDidMount(event): any {
    tippy(event.el, {
      content:
        '<div><span onclick="Window.AvailabilityPlannerComponent.addAvailability(\'' +
        event.event.extendedProps.eventId +
        '\')"><i class="bi bi-pen fa-2x fa-clickable"></i></span>' +
        '<span onclick="Window.AvailabilityPlannerComponent.deleteEvent(' +
        event.event.id +
        ')" ><i class="fbi bi-trash fa-2x fa-clickable">' +
        '</i></span> </div>',
      allowHTML: true,
      plugins: [inlinePositioning, followCursor],
      theme: 'light',
      trigger: 'click',
      followCursor: 'initial',
      arrow: roundArrow,
      popperOptions: { strategy: 'fixed' },
      interactive: true,
      interactiveBorder: 30,
      interactiveDebounce: 0,
      appendTo: document.body,
      animation: 'scale-extreme',
      onMount(instance): void {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.classList.add('animated');
          box.classList.add('wobble');
        });
      },
    });
  }
}
