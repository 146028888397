import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ClockEventOneDayDto } from '../../model/clock-event-dto.model';
import { TimeRecordingService } from '../../services/time-recording.service';

@Component({
  selector: 'app-employee-time-list',
  templateUrl: './employee-time-list.component.html',
  styleUrls: ['./employee-time-list.component.css'],
})
export class EmployeeTimeListComponent implements OnDestroy {
  eventListAccordion: ClockEventOneDayDto[];
  private subscriptions: Subscription[] = [];
  maxDate = dayjs(); // Current date
  minDate = dayjs().subtract(3, 'years'); // Three years back

  timeRange = {
    StarTime: dayjs().startOf('month').toDate(),
    EndTime: dayjs().endOf('month').toDate(),
  };

  @ViewChild('picker') picker: MatDatepicker<any>;


  constructor(private timeRecordingService: TimeRecordingService) {}

  ngOnInit(): void {
    const clockEventListSub =
      this.timeRecordingService.clockEventList.subscribe(
        (events: ClockEventOneDayDto[]) => {
          if (events && events.length > 0) {
            this.eventListAccordion = events;
          } else {
            this.eventListAccordion = [];
          }
        }
      );

    this.subscriptions.push(clockEventListSub);
    this.timeRecordingService.getClockEventsForMultipleDays(this.timeRange.StarTime, this.timeRange.EndTime);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // Handler for date changes
  onDateChange(event: any) {
    this.timeRecordingService.getClockEventsForMultipleDays(this.timeRange.StarTime, this.timeRange.EndTime);
  }

  // Close picker and trigger event on close
  onDatePickerClose() {
    this.onDateChange(null);  // Call the change handler
  }

  handleClockEvent(event: boolean) {
    this.timeRecordingService.getClockEventsForMultipleDays(this.timeRange.StarTime, this.timeRange.EndTime);
  }
}
