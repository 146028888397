import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddAbsenceComponent } from '../../component/add-absence/add-absence.component';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-absence',
  templateUrl: './absence.component.html',
  styleUrls: ['./absence.component.css'],
})
export class AbsenceComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public absenceService: AbsenceService
  ) {}

  ngOnInit(): void {
    this.absenceService.GetAbsenceRequests();
  }

  addAbsence() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddAbsenceComponent, dialogConfig);
  }
}
