<nav class="navbar navbar-expand fixed-top navbar-default" style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);">
    <button class="navbar-toggler" type="button" (click)="isOpen()">
    <mat-icon>menu</mat-icon>
  </button>
    <a class="navbar-brand" href="javascript:void(0)" (click)="onDashboardClickImage()">
        <img src="../../../../../assets/img/logo-no-name.png" alt="" width="40" height="40" />
        <img src="../../../../../assets/img/Logo.PNG" alt="" width="110" height="25" />
    </a>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
            <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item" [ngClass]="{ active: router.url === '/dashboard' }">
                <a class="nav-link" (click)="onDashboardClick()" href="javascript:void(0)"><i class="fas fa-desktop"></i>
          Dashboard<span class="sr-only">(current)</span></a>
            </li>
            <li *ngIf="!authService.isManegeOrTeamLeader" class="nav-item">
                <a class="nav-link" (click)="onEmployeeDashboardClick()" href="javascript:void(0)"><i
            class="fas fa-desktop"></i> Dashboard
          <span class="sr-only">(current)</span></a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item" [ngClass]="{ active: router.url === '/planner' }">
                <a class="nav-link" (click)="onPlannerClick()" href="javascript:void(0)"><i class="fas fa-tasks"></i> {{
          "Planner" | translate }}</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader  && subscriptionService.hasFeature(3)" class="nav-item" [ngClass]="{ active: router.url === '/availability' }">
                <a class="nav-link" (click)="onAvailabilityClick()" href="javascript:void(0)"><i
            class="fa-light fa-user-check"></i> {{
          "AVAILABILITY" | translate }}</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item" [ngClass]="{ active: router.url === '/employee' }">
                <a class="nav-link" (click)="onEmployeeClick()" href="javascript:void(0)"><i class="fa-solid fa-users-gear"></i>
          {{
          "Employee" | translate }}</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader && subscriptionService.hasFeature(9)" class="nav-item" [ngClass]="{ active: router.url === '/time-recording' }">
                <a class="nav-link" (click)="onTimeRecordingClick()" href="javascript:void(0)"><i
            class="fa-light fa-calendar-clock"></i>
          Zeiterfassung</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item" [ngClass]="{ active: router.url === '/absence' }">
                <a class="nav-link" (click)="onAbsenceClick()" href="javascript:void(0)"><i
            class="fa-light fa-house-person-leave"></i> {{
          "Absence" | translate }}</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item" [ngClass]="{ active: router.url === '/resource-plan' }">
                <a class="nav-link" (click)="onResourcePlanClick()" href="javascript:void(0)"><i
            class="fa-light fa-people-group"></i> {{
          "RESOURCE PLAN" | translate }}</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader && subscriptionService.hasFeature(4)" class="nav-item" [ngClass]="{ active: router.url === '/reports' }">
                <a class="nav-link" (click)="onReportsClick()" href="javascript:void(0)"><i class="fal fa-file-chart-line"></i>
          {{
          "REPORTS" | translate }}</a>
            </li>
            <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item" [ngClass]="{ active: router.url === '/settings' }">
                <a class="nav-link" (click)="onSettingsClick()" href="javascript:void(0)"><i class="fa-light fa-gears"></i> {{
          "SETTINGS" | translate }}</a>
            </li>

        </ul>
        <ul class="nav navbar-nav">
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bi bi-translate"></i> {{ lang }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" (click)="changeLocale('en')" href="javascript:void(0)">English</a>
                    <a class="dropdown-item" (click)="changeLocale('de')" href="javascript:void(0)">Deutsch</a>
                </div>
            </li>
        </ul>
    </div>
    <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block ml-auto"></div>
    <li style="list-style-type: none" class="nav-item dropdown">
        <a style="width: 140px; text-align: right" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa-light fa-circle-user fa-xl"></i> {{ authService.firstName }}
        </a>
        <ul class="dropdown-menu col-xs-12" aria-labelledby="navbarDropdownMenuLink" style="width: 300px; white-space: nowrap">
            <li class="divider"></li>
            <li>
                <a class="dropdown-item w-100" (click)="onChangePassword()" href="javascript:void(0)" style="padding-left: 5px">
                    <i class="bi bi-key"></i> {{ "CHANGE PASSWORD" | translate }}
                </a>
            </li>
            <hr class="mt-1 mb-1" />
            <li>
                <a class="dropdown-item w-100" (click)="onLogoutClick()" href="javascript:void(0)" style="padding-left: 5px">
                    <i class="bi bi-box-arrow-right"></i> {{ "Logout" | translate }}
                </a>
            </li>
        </ul>
    </li>
    <div *ngIf="loading" class="progress-container">
        <mat-progress-bar class="audio-progress-bar" mode="indeterminate"></mat-progress-bar>
    </div>
</nav>
