import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from './core/services/dialog/dialog.service';
import { FcmService } from './core/services/notification/fcm.service';
import { PushNotificationsService } from './core/services/notification/push-notifications.service';
import { UpdateService } from './core/services/pwa/update.service';
import { AuthService } from './modules/auth/services/auth/auth.service';
import { CompanySettingsService } from './modules/settings/services/company-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'FixPlaner';
  isMobile: boolean;
  currentRoute: string;
  isLoggedin: boolean;

  constructor(
    private notification: PushNotificationsService,
    private sw: UpdateService,
    translate: TranslateService,
    private companySettings: CompanySettingsService,
    private fcmService: FcmService,
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    const userLanguage = navigator.language.split('-');
    translate.setDefaultLang(userLanguage[0]);
    translate.use(localStorage.getItem('lang') || userLanguage[0]);
    this.notification.requestPermission();
    this.sw.checkForUpdates();
    this.companySettings.getCompanySettings();
    this.fcmService.initPush();

    this.router.events.subscribe((val: any) => {
      // check current route from val.snapshot.routeConfig.path
      if (val.snapshot?.routeConfig?.path) {
        this.currentRoute = val.snapshot.routeConfig.path;

        if (
          this.currentRoute == 'login' ||
          this.currentRoute == 'registration' ||
          this.currentRoute == 'forgotpassword' ||
          this.currentRoute == 'reset-password' ||
          this.currentRoute == 'email-confirmation' ||
          this.currentRoute == 'change-password' ||
          this.currentRoute == 'resetpassword' ||
          this.currentRoute == 'accept-invitation' ||
          this.currentRoute == 'payment-confirmation'
        ) {
          this.isLoggedin = false;
        } else {
          this.isLoggedin = true;
        }

        if (this.currentRoute == 'registered-stepper') {
          this.dialogService.setDialogOpen(true); // Dialog geöffnet
        } else {
          this.dialogService.setDialogOpen(false); // Dialog geschlossen
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });

    this.dialogService.dialogOpen$.subscribe((isOpen) => {
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        if (isOpen) {
          navbar.classList.add('navbar-disabled');
        } else {
          navbar.classList.remove('navbar-disabled');
        }
      }
    });
  }

  // ngOnInit() {
  //   PushNotifications.requestPermissions().then((result) => {
  //     if (result.receive === 'granted') {
  //       PushNotifications.register().then(() => {
  //         // Register with Apple / Google to receive push via APNS/FCM
  //         PushNotifications.deleteChannel({id: 'PushDefaultForeground'}).then((removed) => {
  //         });

  //         PushNotifications.createChannel({
  //           id: 'fixplaner',
  //           name: 'FixPlanerNotifications',
  //           description: 'FixPlanerNotifications Channel for Push Notifications on Android',
  //           importance: 5,
  //           visibility: 1,
  //           sound: 'beep.wav',
  //           lightColor: '#FF0000',
  //           vibration: true,
  //           lights: true,
  //         });

  //       }).catch((error) => {
  //         // Handle registration error
  //         console.log(error);
  //       });
  //     } else {
  //       // Show some error
  //     }
  //   });

  //   PushNotifications.addListener('registration', (token: Token) => {
  //     this.fcmService.AddAccessToken(token.value);
  //   });

  //   PushNotifications.addListener('registrationError', (error: any) => {
  //     // Handle push notification registration error here.
  //     alert('Error on registration: ' + JSON.stringify(error));
  //   });

  //   PushNotifications.addListener(
  //     'pushNotificationReceived',
  //     (notification: PushNotificationSchema) => {

  //     }
  //   );

  //   PushNotifications.addListener(
  //     'pushNotificationActionPerformed',
  //     (notification: ActionPerformed) => {
  //       // Implement the needed action to take when user tap on a notification.
  //     }
  //   );
  // }
}
