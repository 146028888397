<header class="header" id="header">
  <nav class="nav">
    <a class="nav__logo" href="javascript:void(0)">
      <img
        src="../../../../../assets/img/logo-no-name.png"
        alt=""
        width="40"
        height="40"
      />
      <img
        src="../../../../../assets/img/Logo.PNG"
        alt=""
        width="150"
        height="30"
      />
    </a>

    <li style="list-style-type: none" class="nav-item dropdown">
      <a
        style="width: 140px; text-align: right"
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="fa-light fa-circle-user fa-xl"></i>
        {{ authService.firstName }}
      </a>
      <ul
        class="dropdown-menu col-xs-12"
        aria-labelledby="navbarDropdownMenuLink"
        style="white-space: nowrap"
      >
        <li class="divider"></li>
        <li>
          <a
            class="dropdown-item w-100"
            (click)="onChangePassword()"
            href="javascript:void(0)"
            style="padding-left: 5px"
          >
            <i class="bi bi-key"></i> {{ "CHANGE PASSWORD" | translate }}
          </a>
        </li>
        <hr class="mt-1 mb-1" />
        <li>
          <a
            class="dropdown-item w-100"
            (click)="onLogoutClick()"
            href="javascript:void(0)"
            style="padding-left: 5px"
          >
            <i class="bi bi-box-arrow-right"></i> {{ "Logout" | translate }}
          </a>
        </li>
        <hr class="mt-1 mb-1" />
        <li>
          <a
            class="dropdown-item w-100"
            href="javascript:void(0)"
            style="padding-left: 5px"
          >
            <i class="fal fa-mobile-alt"></i> {{ version }}
          </a>
        </li>
      </ul>
    </li>
  </nav>
  <div class="nav__menu" id="nav-menu">
    <ul class="nav__list">
      <li class="nav__item" *ngIf="showNotificationBadge">
        <a
          id="employee-notification-nav"
          (click)="onNavClick('employee-notification')"
          href="javascript:void(0)"
          class="nav__link"
        >
          <i
            class="fa-light fa-bells fa-shake nav__icon"
            style="--fa-animation-duration: 15s"
          >
          </i>
          <span class="nav__name child">{{ "NOTIFICATION" | translate }}</span>
        </a>
      </li>
      <li class="nav__item">
        <a
          id="show-employee-work-shifts-nav"
          (click)="onNavClick('show-employee-work-shifts')"
          href="javascript:void(0)"
          class="nav__link"
        >
          <i class="fas fa-tasks nav__icon"></i>
          <span class="nav__name">{{ "WORK SCHEDULE" | translate }}</span>
        </a>
      </li>
      <li class="nav__item">
        <a
          id="employee-absence-calendar-nav"
          href="javascript:void(0)"
          class="nav__link"
          (click)="onNavClick('employee-absence-calendar')"
        >
          <i class="fa-light fa-house-person-leave nav__icon"></i>
          <span class="nav__name">{{ "Absence" | translate }}</span>
        </a>
      </li>
      <li class="nav__item">
        <a
          id="availability-planner-nav"
          (click)="onNavClick('availability-planner')"
          href="javascript:void(0)"
          class="nav__link"
        >
          <i class="fa-light fa-user-check nav__icon"></i>
          <span class="nav__name">{{ "AVAILABILITY" | translate }}</span>
        </a>
      </li>
      <li class="nav__item">
        <a
          id="absence-holiday-chart-nav"
          (click)="onNavClick('absence-holiday-chart')"
          href="javascript:void(0)"
          class="nav__link"
        >
          <i class="fa-light fa-chart-pie nav__icon"></i>
          <span class="nav__name">Portfolio</span>
        </a>
      </li>
    </ul>
  </div>
</header>
