import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { EChartsOption } from 'echarts';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { EventType } from 'src/app/core/services/const';
import { AvailabilityService } from 'src/app/modules/availability/services/availability.service';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import tippy, {
  followCursor,
  hideAll,
  inlinePositioning,
  roundArrow,
} from 'tippy.js';
import { v4 as uuidv4 } from 'uuid';
import { AddScheduleRequirementComponent } from '../../../admin/component/add-schedule-requirement/add-schedule-requirement.component';
import { ShowWorkShiftDetailsComponent } from '../../../admin/component/show-work-shift-details/show-work-shift-details.component';
import { EmployeeListByDateDto } from '../../../admin/model/department-dto.model';
import { ResourceXY } from '../../../admin/model/resource-x-y.model';
import { ScheduleRequirementEvent } from '../../../admin/model/schedule-requirement-event';
import { ScheduleRequirementService } from '../../../admin/services/schedule-requirement.service';
import { DailyAvailability } from '../../model/daily-availability';
import { AddAvailabilityComponent } from '../add-availability/add-availability.component';
@Component({
  selector: 'app-employee-availability-timeline',
  templateUrl: './employee-availability-timeline.component.html',
  styleUrls: ['./employee-availability-timeline.component.css'],
})
export class EmployeeAvailabilityTimelineComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /*.initOptions..*/

  currentCalendarData: any;
  isAdding = false;
  isCopy = false;
  queriedEmployee = 0;
  queriedPositionId = 0;
  calenderPosition: any = null;
  currentEventId: null;
  resourceListXY: ResourceXY[] = [];
  calenderEventsBackup = null;
  IncomeTypeId = '';

  private subscriptionAvailabilityEventList: Subscription;
  chartOption2: EChartsOption;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  calendarApi: any;
  HTMLElement: HTMLImageElement;
  EmployeeSearchId: number;
  eventNumber = 1;
  alertMessage: string = this.translate.instant(
    'BEFORE PROCEEDING WITH THIS VIEW WE WOULD LIKE TO REMIND YOU THAT YOU '
  );

  public filteredCategory: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  employeeCategory: EmployeeListByDateDto[];
  protected _onDestroy = new Subject<void>();
  @ViewChild('EmployeeSearch', { static: true }) categorySelect: MatSelect;
  calendarOptions: CalendarOptions = {
    plugins: [resourceTimelinePlugin, interactionPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: this.companyService.CompanySettings.CalendarOptions.FirstDay,
    initialView: 'customWeek',
    slotEventOverlap: false,
    slotMinTime:
      this.companyService.CompanySettings.CalendarOptions
        .BusinessHoursStartTime,
    slotMaxTime:
      this.companyService.CompanySettings.CalendarOptions.BusinessHoursEndTime,
    slotDuration: '24:00:00',
    weekNumbers: true,
    resourceAreaWidth: '13%',
    height: 'auto',
    resourcesInitiallyExpanded: true,
    resourceAreaColumns: [
      // {
      //   group: true,
      //   field: 'departement',
      //   headerContent: 'Departement',
      // },
      {
        field: 'title',
      },
    ],
    resourceGroupField: 'departement',
    customButtons: {
      prev: {
        click: () => this.selectPrevButton(),
      },
      next: {
        click: () => this.selectNextButton(),
      },
      customWeekButton: {
        text: this.translate.instant('Week'),
        click: () => this.selectCustomWeekButton(),
      },
    },
    headerToolbar: {
      left: 'prev,next',
      center: '',
      right: 'customDay,customWeekButton',
    },
    views: {
      customDay: {
        type: 'resourceTimeline',
        duration: { day: 1 },
        slotDuration: { hour: 0.5 },
        buttonText: this.translate.instant('Day'),
        titleFormat: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
        slotLabelFormat: [
          { weekday: 'long' }, // top level of text
          { hour: '2-digit' }, // lower level of text
        ],
      },
      customWeek: {
        type: 'resourceTimeline',
        duration: { weeks: 1 },
        slotDuration: { days: 1 },
        buttonText: this.translate.instant('Week'),
        slotLabelFormat: [
          {
            weekday: 'long',
          },
        ],
      },
      customMonth: {
        type: 'resourceTimeline',
        duration: { month: 1 },
        slotDuration: { days: 1 },
        buttonText: this.translate.instant('Month'),
        slotLabelFormat: [
          function (date: any) {
            return (
              'Woche(' +
              (
                dayjs(date.date).week() -
                dayjs(date.date).startOf('month').week() +
                1
              ).toString() +
              ')'
            );
          },
          function (date: any) {
            return dayjs(date.date).format('ddd');
          },
        ],
      },
    },
    businessHours: {
      startTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursStartTime,
      endTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursEndTime,

      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      // days of week. an array of zero-based day of week integers (0=Sunday)
      // (Monday-Thursday in this example)
    },
    weekText: this.translate.instant('calendar week'),
    resourceOrder: 'sortOrder',
    aspectRatio: 1.6,
    displayEventTime: true,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    handleWindowResize: true,
    eventReceive: this.handleDropInfo.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventResizeStart: this.handleEventResize.bind(this),
    eventContent: this.handleEventContent.bind(this),
    select: this.handleDateSelect.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    resourceLabelContent: this.handleResourceLabelContent.bind(this),
    slotLabelContent: this.handleSlotLabelContent.bind(this),
  };

  @ViewChild('calendarSchedule') calendarComponent: FullCalendarComponent;
  @ViewChild('calendarSchedule') calendarComponentPostition: ElementRef;

  @ViewChild('table.fc-scrollgrid') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  constructor(
    private service: ScheduleRequirementService,
    private cdr: ChangeDetectorRef,
    private departementService: DepartementService,
    private dialog: MatDialog,
    private availabilityService: AvailabilityService,
    private el: ElementRef,
    private translate: TranslateService,
    private zone: NgZone,
    private companyService: CompanySettingsService
  ) {
    Window['EmployeeAvailabilityTimelineComponent'] = this;
  }
  private subscriptionEmployeeEventList: Subscription;
  ngOnInit(): void {
    this.subscriptionEmployeeEventList =
      this.availabilityService.availabilityTimelineEventList.subscribe(
        (timelineEvents) => {
          if (timelineEvents == null) {
            return;
          }
          const calenderEventsCopy = JSON.stringify(
            timelineEvents.calenderEvents
          );
          if (this.calendarComponent) {
            const calendar = this.calendarComponent.getApi();
            if (
              calendar &&
              JSON.stringify(this.calendarOptions.events) !== calenderEventsCopy
            ) {
              calendar.removeAllEvents();
            }
          }
          this.eventNumber = timelineEvents.calenderResources.length;
          this.calenderEventsBackup = calenderEventsCopy;
          this.calendarOptions.events = timelineEvents.calenderEvents;
          this.calendarOptions.resources = timelineEvents.calenderResources;
        }
      );

    this.subscriptionAvailabilityEventList =
      this.availabilityService.availabilityEventList.subscribe((list: []) => {
        if (list.length) {
          list.forEach((entry: any) => {
            entry.display = 'inverse-background';
            entry.groupId = 'available';
          });
          this.calendarOptions.events = (
            this.calendarOptions.events as []
          )?.filter((item: any) => item.eventId !== this.currentEventId);

          (this.calendarOptions.events as [])?.push(...list);
        }
      });
  }
  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent?.getApi();
    if (this.service.calendarCurrentStart && this.calendarApi) {
      this.calendarApi.gotoDate(this.service.calendarCurrentStart);
    }
    this.currentCalendarData = this.calendarApi?.getCurrentData();
    this.availabilityService.getAvailabilityTimelineEvent();

    this.HTMLElement = this.el.nativeElement.querySelector(
      'table.fc-scrollgrid'
    );
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeEventList.unsubscribe();
  }
  openDeleteConfirmationDialog(): void {
    let textDialog = '';
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();
    if (this.currentCalendarData.currentViewType !== 'customWeek') {
      textDialog +=
        '<b>Es kann nur einen Wochenplan gelöscht werden. </b><br/>';
      calendar.changeView('customWeek');
      this.service.getTimelineEvent();
    }
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    textDialog +=
      'Wollen sie den Plan vom <b>' +
      dayjs(this.currentCalendarData.viewApi.currentStart).format('DD.MM') +
      '</b> bis zum <b>' +
      dayjs(this.currentCalendarData.viewApi.currentEnd).format('DD.MM') +
      '</b> löschen?';
    this.dialogRef.componentInstance.confirmMessage = textDialog;

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deletePlanButton();
      }
      this.dialogRef = null;
    });
  }
  deletePlanButton(): void {
    const calendar = this.calendarComponent.getApi();
    const currentData = calendar.getCurrentData();
    this.service.deleteTimelineWorkPlan(
      currentData.viewApi.currentStart,
      currentData.viewApi.currentEnd
    );
  }
  selectPrevButton(): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();

    if (this.currentCalendarData.currentViewType === 'customWeek') {
      return;
    }

    calendar.prev();
    this.currentCalendarData = calendar.getCurrentData();
    this.service.getTimelineEvent();
    this.departementService.getDepartementEmployeeList(
      this.currentCalendarData.viewApi.currentStart,
      this.service.selectedDepartementList
    );
  }
  selectNextButton(): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();

    if (this.currentCalendarData.currentViewType === 'customWeek') {
      return;
    }
    calendar.next();
    this.currentCalendarData = calendar.getCurrentData();
    this.service.getTimelineEvent();
    this.departementService.getDepartementEmployeeList(
      this.currentCalendarData.viewApi.currentStart,
      this.service.selectedDepartementList
    );
  }

  selectCustomWeekButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('customWeek');
    this.currentCalendarData = calendar.getCurrentData();
    this.service.getTimelineEvent();
    this.departementService.getDepartementEmployeeList(
      this.currentCalendarData.viewApi.currentStart,
      this.service.selectedDepartementList
    );
  }
  editWorkShift(eventId: string): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();

    setTimeout(() => {
      hideAll();
    }, 100);
    const list = this.calendarOptions.events;
    const shift = (list as []).find((x: any) => x.eventId === eventId) as any;
    this.addWorkShift({
      StarTime: shift.start,
      EndTime: shift.end,
      EmployeeId: shift.employeeId,
      DepartementId: shift.departmentId,
      EventId: eventId,
      calendarViewDay: this.currentCalendarData.currentViewType === 'customDay',
      isOpenShift: shift.eventType === EventType.OpenShift,
      addWorkShift: false,
      SkillId: shift.skillId,
      NumberOfStaff: shift.numberOfStaff,
    });
  }
  handleDateSelect(selectInfo: DateSelectArg): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';

    //StarTime: selectInfo.startStr,
    //   EndTime: selectInfo.endStr,
    //   DepartementId: selectInfo.resource._resource.extendedProps.departementId,
    //   EmployeeId: selectInfo.resource._resource.extendedProps.employeeId,
    //   calendarViewDay: this.currentCalendarData.currentViewType === 'customDay',
    //   addWorkShift: true,
    //   // convert id to number
    //   SkillId: +selectInfo.resource._resource.id,

    dialogConfig.data = {
      Start: selectInfo.startStr,
      End: selectInfo.endStr,
      Weekday: new Date(selectInfo.startStr).getDay().toString(),
      isWeekendVisible: false,
      EmployeeId: selectInfo.resource._resource.extendedProps.employeeId,
    } as DailyAvailability;

    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(AddAvailabilityComponent, dialogConfig);
    });
  }

  addWorkShift(workShift: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = { workShift };
    this.zone.run(() => {
      this.dialog.open(AddScheduleRequirementComponent, dialogConfig);
    });
  }

  addAvailability(eventId = null): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';

    if (eventId) {
      const shift = (this.calendarOptions.events as []).find(
        (x: any) => x.eventId === eventId
      ) as any;
      dialogConfig.data = {
        Start: shift.startTime,
        End: shift.endTime,
        Id: shift.id,
        Weekday: shift.dayOfTheWeek ?? '0',
        isWeekendVisible: false,
        EmployeeId: shift.employeeId,
      } as DailyAvailability;
    }

    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(AddAvailabilityComponent, dialogConfig);
    });
  }

  deleteEvent(id, EmployeeId): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to Delete this Availability Slot';

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.currentCalendarData) {
          const calendar = this.calendarComponent.getApi();
          this.currentCalendarData = calendar.getCurrentData();
        }
        this.availabilityService.deleteAvailability(id, EmployeeId);
      }
      this.dialogRef = null;
    });
  }

  handleDropInfo(events: any): void {
    if (events.event.extendedProps.eventId) {
      return;
    }

    this.isAdding = true;
    const event = new ScheduleRequirementEvent();
    event.StarTime = events.event.startStr;
    event.EventId = uuidv4();
    events.event.setExtendedProp('eventId', event.EventId);
    this.queriedEmployee = 0;
    this.service.addEvent(event);
    this.cdr.detectChanges();
  }

  handleEventChange(events: any): void {
    if (this.isCopy) {
      const event = new ScheduleRequirementEvent();
      event.StarTime = events.event.startStr;
      if (events.event.endStr !== '') {
        event.EndTime = events.event.endStr;
      }
      event.EventId = uuidv4();
      this.queriedEmployee = 0;
      this.service.addEvent(event);
    }
    if (!this.isAdding && !this.isCopy) {
      const event = new ScheduleRequirementEvent();
      event.StarTime = events.event.startStr;
      if (events.event.endStr !== '') {
        event.EndTime = events.event.endStr;
      }
      event.EventId = events.event.extendedProps.eventId;
      this.queriedEmployee = 0;
      this.queriedPositionId = 0;
      event.SkillId = events.event.extendedProps.skillId;
      event.NumberOfStaff = events.event.extendedProps.numberOfStaff;
      this.service.updateEvent(event);
    }
    this.isAdding = false;
    this.isCopy = false;
    this.cdr.detectChanges();
  }

  handleEventResize(event: any): void {
    if (parseInt(event.event.id) !== this.queriedEmployee) {
      this.currentEventId = event.event.extendedProps.eventId;
      this.getEmployeeAvailability(event.event.id);
    }
  }
  handleEventContent(event): any {
    if (
      event.isDraggable &&
      event.isDragging &&
      parseInt(event.event.id) !== this.queriedEmployee
    ) {
      this.currentEventId = event.event.extendedProps.eventId;
      this.getEmployeeAvailability(event.event.id);
    }

    // get hours and minutes from the event start and end time and show in HH:MM format
    const start = dayjs(event.event.start);
    const end = dayjs(event.event.end);
    const hours = end.diff(start, 'hours');
    const minutes = end.diff(start, 'minutes') - hours * 60;

    let hoursString = hours.toString();
    let minutesString = minutes.toString();

    if (hours < 10) {
      hoursString = '0' + hours;
    }
    if (minutes < 10) {
      minutesString = '0' + minutes;
    }

    return {
      html:
        '<div class="fc-event-time">' +
        event.timeText +
        '  ' +
        // show the icon in the right side of the event
        '<i style="float: right" class="hidden-mobile bi bi-clock"> ' +
        hoursString +
        ':' +
        minutesString +
        '</i>' +
        '</div>' +
        event.event._def.title +
        ' <em class="' +
        event.event._def.extendedProps.icon +
        ' fa-lg' +
        '"></em> ',
    };
  }
  getEmployeeAvailability(id: string): void {
    this.queriedEmployee = parseInt(id);
    this.availabilityService.getAvailabilityEvent(false, this.queriedEmployee);
  }

  getAvailabilityPositionId(id: number): void {
    this.queriedPositionId = id;
    this.currentEventId = null;
    this.calendarOptions.events = JSON.parse(this.calenderEventsBackup);
    this.availabilityService.getAvailabilityPositionId(
      false,
      this.queriedPositionId
    );
  }
  showWorkShiftDetails(eventId, eventType): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.data = { eventId, eventType };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(ShowWorkShiftDetailsComponent, dialogConfig);
    });
  }

  handleEventDidMount(event): any {
    // var dataToFind = moment(event.start).format('YYYY-MM-DD');
    // $("td[data-date='" + dataToFind + "']").addClass('activeDay')
    tippy(event.el, {
      content:
        '<div><span onclick="Window.EmployeeAvailabilityTimelineComponent.addAvailability(\'' +
        event.event.extendedProps.eventId +
        '\')"><i class="bi bi-pen fa-lg fa-clickable"></i></span>' +
        '<span onclick="Window.EmployeeAvailabilityTimelineComponent.deleteEvent(\'' +
        event.event.id +
        "'," +
        event.event.extendedProps.employeeId +
        ')" ><i class="far fa-trash-alt fa-lg fa-clickable">' +
        '</i></span> </div>',
      allowHTML: true,
      plugins: [inlinePositioning, followCursor],
      theme: 'light',
      trigger: 'click',
      followCursor: 'initial',
      arrow: roundArrow,
      popperOptions: { strategy: 'fixed' },
      interactive: true,
      interactiveBorder: 30,
      interactiveDebounce: 0,
      appendTo: document.body,
      animation: 'scale-extreme',
      onMount(instance): void {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.classList.add('animated');
          box.classList.add('wobble');
        });
      },
    });
  }
  handleDayClick(clickedDay): any {
    clickedDay = dayjs(clickedDay).toDate();
    const calendar = this.calendarComponent.getApi();
    calendar.gotoDate(clickedDay);
    calendar.changeView('customDay');
  }
  handleSlotLabelContent(event): any {
    if (
      (event.level === 1 &&
        this.currentCalendarData?.currentViewType !== 'customDay') ||
      event.level === 0
    ) {
      return {
        html:
          '<span class="time-line-header-day" onclick="Window.EmployeeAvailabilityTimelineComponent.handleDayClick(\'' +
          dayjs(event.date).format('YYYY-MM-DD') +
          '\')">' +
          event.text +
          '</span>',
      };
    }
  }

  handleResourceLabelContent(event): any {
    if (event.resource._resource.extendedProps.departementId > 0) {
      let color = '#1b5e1f8f';
      if (event.resource._resource.extendedProps.timeAvailablePercent >= 80) {
        color = '#F9A8258f';
      }
      if (event.resource._resource.extendedProps.timeAvailablePercent > 99) {
        color = '#B71C1C8f';
      }
      return {
        html:
          '<div class="row" style="display : flex; align-items : flex-end;">' +
          '<div style="padding-left: 0px;padding-right: 0px;" class="col-md-3 mb-2">' +
          '</div> <div style="padding-left: 0px;padding-right: 0px; font-size: medium; margin-bottom: 0px !important;" class="col-md-9 mb-4"> ' +
          event.resource._resource.title +
          '  ' +
          event.resource._resource.extendedProps.skillIcons +
          '</div></div>',
      };
    }
  }
}
