import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { FcmService } from 'src/app/core/services/notification/fcm.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { environment } from 'src/environments/environment';
import {
  ApiMethod,
  AuthEndPoints,
  LoginErrorType,
} from '../../../../core/services/const';
import { HttpService } from '../../../../core/services/http/http.service';
import { UserCompany } from '../../models/user-company';
import { AuthResponseDto } from './response/authResponseDto.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isManegeOrTeamLeader = false;
  public myRole = 0;

  public error = {
    showError: false,
    errorMessage: '',
  };

  public isLoggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  public firstName = '';
  public Email = null;
  constructor(
    private fb: UntypedFormBuilder,
    private httpClient: HttpService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private toastr: ToastrService,
    private translate: TranslateService,
    private companySettings: CompanySettingsService,
    private fcmService: FcmService,
    private spinner: NgxSpinnerService
  ) {
    this.isManegeOrTeamLeader = this.roleMatch(['MANAGER', 'TeamLeader']);
    this.setMyCurrentRole();
  }
  formModel = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.email],
    passwords: ['', Validators.email],
    // passwords: this.fb.group(
    //   {
    //     password: ['', [Validators.required, Validators.minLength(4)]],
    //     confirmPassword: ['', Validators.required],
    //   },
    //   { validator: this.comparePasswords }
    // ),
  });

  newPasswordForm = this.fb.group(
    {
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: [''],
    },
    { validator: this.checkPasswords }
  );

  checkPasswords(group: UntypedFormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  comparePasswords(fb: UntypedFormGroup) {
    const confirmPswrdCtrl = fb.get('confirmPassword');
    // passwordMismatch
    // confirmPswrdCtrl.errors={passwordMismatch:true}
    if (
      confirmPswrdCtrl.errors == null ||
      'passwordMismatch' in confirmPswrdCtrl.errors
    ) {
      if (fb.get('password').value !== confirmPswrdCtrl.value) {
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      } else {
        confirmPswrdCtrl.setErrors(null);
      }
    }
  }

  forgotPassword(email: string): void {
    return this.httpClient
      .requestCall(AuthEndPoints.ForgotPassword, ApiMethod.POST, {
        Email: email,
      })
      .subscribe((res) => {
        if (res) {
          this.toastr.success(
            this.translate.instant(
              'PLEASE CHECK YOUR EMAIL, WE HAVE SENT YOU A LINK TO RESET YOUR PASSWORD'
            ),
            this.translate.instant('RESET PASSWORD')
          );
          this.error.showError = true;
          this.error.errorMessage = this.translate.instant(
            'PLEASE CHECK YOUR EMAIL, WE HAVE SENT YOU A LINK TO RESET YOUR PASSWORD'
          );
        }
      });
  }

  validateEmail(email): boolean {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
    );
  }

  updateNewPassword(newPasswordForm): void {
    this.spinner.show();
    const postObject = {
      oldPassword: newPasswordForm.oldPassword,
      newPassword: newPasswordForm.password,
      Email: newPasswordForm.email,
    };

    if (newPasswordForm.email) {
      postObject.Email = newPasswordForm.email;
    } else {
      postObject.Email = this.Email;
    }
    this.httpClient
      .requestCall(AuthEndPoints.NewPassword, ApiMethod.POST, postObject)
      .subscribe((res) => {
        if (res && this.validateEmail(postObject.Email)) {
          this.spinner.hide();
          this.login({
            Email: postObject.Email,
            password: newPasswordForm.password,
          });
        } else {
          this.toastr.error('Something went wrong', 'Error');
        }
      });
  }

  EmployeeAccountConfirmation(confirmation): void {
    this.spinner.show();
    this.httpClient
      .requestCall(
        AuthEndPoints.EmployeeAccountConfirmation,
        ApiMethod.POST,
        confirmation
      )
      .subscribe((res) => {
        if (res) {
          this.toastr.success(
            this.translate.instant('YOUR PASSWORD HAS BEEN SET SUCCESSFULLY'),
            this.translate.instant('PASSWORD SET SUCCESSFULLY'),
            {
              timeOut: 20000,
            }
          );

          this.goToLogin();
          this.spinner.hide();
        } else {
          this.toastr.error(
            this.translate.instant('THERE WAS AN ISSUE SETTING YOUR PASSWORD'),
            this.translate.instant('PASSWORD SETTING ERROR'),
            {
              timeOut: 20000,
              extendedTimeOut: 30000,
              progressBar: true,
              closeButton: true,
            }
          );
          this.spinner.hide();
        }
      });
  }

  register(body: UserCompany): void {
    return this.httpClient
      .requestCall('/ApplicationUser/RegisterCompany', ApiMethod.POST, body)
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastr.success(
              this.translate.instant(
                'A NEW USER ACCOUNT HAS BEEN SUCCESSFULLY CREATED'
              ),
              this.translate.instant('REGISTER'),
              {
                timeOut: 10000,
                extendedTimeOut: 10000,
                progressBar: true,
                closeButton: true,
              }
            );

            this.login(
              {
                Email: body.Email,
                Password: body.Password,
              },
              true
            );
          } else {
            this.toastr.error(
              'Password or Email is incorrect',
              'can not Register'
            );
          }
          this.spinner.hide();
          // this.personService.getPerson();
        },
        (err) => {
          this.spinner.hide();
          if (err.status === 400) {
            this.toastr.error(
              this.translate.instant(err.error),
              this.translate.instant('ERROR'),
              {
                timeOut: 20000,
                extendedTimeOut: 30000,
                progressBar: true,
                closeButton: true,
              }
            );
          } else {
            console.log(err);
          }
        }
      );
  }

  resetPassword(body: any): void {
    return this.httpClient
      .requestCall(AuthEndPoints.ResetPassword, ApiMethod.POST, body)
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastr.success(
              'Password reset successfully',
              'Reset Password'
            );
            this.router.navigateByUrl('/login');
          }
        },
        (err) => {
          if (err.status === 400) {
            //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          } else {
            console.log(err);
          }
        }
      );
  }

  // tryRefreshingTokens
  tryRefreshingTokens(token): Observable<AuthResponseDto> {
    return new Observable<AuthResponseDto>((observer) => {
      const refreshToken: string = localStorage.getItem('refreshToken');
      if (!token || !refreshToken) {
        observer.error('Token or refreshToken is missing');
        return;
      }

      // Make a request to refresh the tokens using the refresh token
      this.httpClient
        .requestCall('/ApplicationUser/RefreshToken', ApiMethod.POST, {
          AccessToken: token,
          RefreshToken: refreshToken,
        })
        .subscribe({
          next: (res: AuthResponseDto) => {
            if (res.Token !== null && res.IsAuthSuccessful) {
              localStorage.setItem('token', res.Token);
              localStorage.setItem('refreshToken', res.RefreshToken);
              observer.next({
                Token: res.Token,
                RefreshToken: res.RefreshToken,
                IsAuthSuccessful: res.IsAuthSuccessful,
                ErrorMessage: res.ErrorMessage,
              });
              observer.complete();
            }
          },
          error: (err: HttpErrorResponse) => {
            observer.error('Failed to refresh tokens');

            // If there is an error, logout the user
            this.goToLogin();
          },
        });
    });
  }

  login(formData, firstTime: boolean = false): void {
    this.spinner.show();
    return this.httpClient
      .requestCall('/ApplicationUser/Login', ApiMethod.POST, formData)
      .subscribe({
        next: async (res: AuthResponseDto) => {
          if (res.Token !== null && res.IsAuthSuccessful) {
            localStorage.setItem('token', res.Token);
            localStorage.setItem('refreshToken', res.RefreshToken);
            // send clientToken MobileD to server to save it in db
            this.fcmService.sendTokenToServer();
            await this.companySettings.initGetCompanySettings();
            this.isLoggedin.next(true);
            //  this.fcmService.initPush();
            this.isManegeOrTeamLeader = this.roleMatch([
              'MANAGER',
              'TeamLeader',
            ]);
            this.setMyCurrentRole();
            if (this.isManegeOrTeamLeader) {
              if (firstTime) {
                this.router.navigateByUrl('/registered-stepper');
              } else {
                this.router.navigateByUrl('/dashboard');
              }
            } else {
              this.router.navigateByUrl('/employeedashboard');
            }
            this.toastr.success(
              this.translate.instant('LOGIN SUCCESSFULLY'),
              this.translate.instant('LOG IN')
            );
            this.error.showError = false;
            this.IsEmailConfirmed(formData.Email);
          } else {
            this.toastr.error(
              'Password or Email is incorrect',
              'can not login'
            );
          }
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          // case switch with LoginErrorType enum from const file
          this.spinner.hide();
          switch (err.error.ErrorType) {
            case LoginErrorType.None:
              this.toastr.error(
                this.translate.instant('AN ERROR HAS OCCURRED'),
                this.translate.instant('AN ERROR HAS OCCURRED')
              );
              break;
            case LoginErrorType.UserNotFound:
              {
                this.toastr.error(
                  this.translate.instant(
                    'USER WITH THIS EMAIL ADDRESS WAS NOT FOUND'
                  ),
                  this.translate.instant('USER NOT FOUND')
                );
                this.error.showError = true;
                this.error.errorMessage = this.translate.instant(
                  'USER WITH THIS EMAIL ADDRESS WAS NOT FOUND'
                );
              }
              break;
            case LoginErrorType.UserLockedOut:
              {
                this.toastr.error(
                  this.translate.instant(
                    'YOU HAVE TRIED MANY TIMES, SO YOUR ACCOUNT IS LOCKED'
                  ),
                  this.translate.instant('YOUR ACCOUNT IS LOCKED')
                );
                this.error.showError = true;
                this.error.errorMessage = this.translate.instant(
                  'YOU HAVE TRIED MANY TIMES, SO YOUR ACCOUNT IS LOCKED'
                );
              }
              break;
            case LoginErrorType.PasswordIncorrect:
              {
                this.toastr.warning(
                  this.translate.instant('THE ENTERED PASSWORD IS INCORRECT'),
                  this.translate.instant('PASSWORD INCORRECT')
                );

                this.error.showError = true;
                this.error.errorMessage = this.translate.instant(
                  'THE ENTERED PASSWORD IS INCORRECT'
                );
              }
              break;

            case LoginErrorType.UserDeactivated:
              {
                this.toastr.error(
                  this.translate.instant(
                    'WE RE SORRY BUT IT APPEARS THAT YOUR ACCOUNT HAS BEEN DEACTIVATED BY THE ORGANIZATION'
                  ),
                  this.translate.instant('ACCOUNT DEACTIVATED')
                );
                this.error.showError = true;
                this.error.errorMessage = this.translate.instant(
                  'WE RE SORRY BUT IT APPEARS THAT YOUR ACCOUNT HAS BEEN DEACTIVATED BY THE ORGANIZATION'
                );
              }
              break;
          }
        },
      });
  }

  setLoginTimestamp(): void {
    localStorage.setItem('lastLoginTime', new Date().toISOString());
  }

  IsEmailConfirmed(eMAIL: string): void {
    return this.httpClient
      .requestCall(AuthEndPoints.IsEmailConfirmed, ApiMethod.POST, {
        Email: eMAIL,
      })
      .subscribe(
        (res: any) => {
          if (!res) {
            this.toastr.warning(
              this.translate.instant(
                'PLEASE CONFIRM YOUR EMAIL ADDRESS TO UNLOCK ALL FEATURES OF THE APP'
              ),
              this.translate.instant('EMAIL CONFIRMATION'),
              {
                timeOut: 30000,
                extendedTimeOut: 10000,
                closeButton: true,
                tapToDismiss: false,
              }
            );
          }
        },
        (err) => {
          if (err.status === 400) {
            //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          } else {
            console.log(err);
          }
        }
      );
  }

  confirmEmail(token, email) {
    const body = {
      token,
      email,
    };

    return this.httpClient.requestCall(
      AuthEndPoints.EmailConfirmation,
      ApiMethod.POST,
      body
    );
  }

  goToLogin(): void {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
    this.isLoggedin.next(false);
  }
  loginExternal(): void {
    this.document.location.href =
      environment.apiURL + '/ApplicationUser/ExternalLogin';
  }
  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    const payLoad = JSON.parse(window.atob(token.split('.')[1]));
    const userRole = payLoad.role;
    this.firstName = payLoad.FirstName;
    this.Email = payLoad.Email;
    allowedRoles.forEach((element) => {
      if (userRole === element) {
        isMatch = true;
        return true;
      }
    });
    return isMatch;
  }

  setMyCurrentRole(): void {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    const payLoad = JSON.parse(window.atob(token.split('.')[1]));
    const userRole = payLoad.role;
    switch (userRole) {
      case 'Employee':
        this.myRole = 2;
        break;
      case 'TeamLeader':
        this.myRole = 3;
        break;
      case 'MANAGER':
        this.myRole = 1;
        break;
      default:
        this.myRole = 0;
        break;
    }
  }
}
