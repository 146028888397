<ion-content style="height: 100vh; overflow-y: hidden;" #content [scrollEvents]="true"
  (ionScroll)="handleScroll($any($event))" class="ion-padding">
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing...">
    </ion-refresher-content>
  </ion-refresher>

  <div class="row pull-to-refresh">
    <div class="col-12 text-center">
      <div *ngIf="showSpinner" class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="row">
    <p *ngIf="showRefreshText" class="bg-light text-center py-2" style="height: 20px; border-radius: 20px;">
      <i class="bi bi-arrow-clockwise"></i> {{ "PULL DOWN TO REFRESH" | translate }}
    </p>
  </div>
  <!-- Your existing content here -->
  <div class="row" style="height: 25px;">
    <h6 *ngIf="showWelcomeMessage" [ngClass]="{'fade-out': fadeOut}"
      style="display: inline-block; color: var(--font-color); padding-left: 15px; padding-top: 5px;">
      {{'WELCOME BACK' | translate}} {{ authService.firstName }}
    </h6>
  </div>
  <div *ngIf="isMobile">
    <div id="employee-notification" class="col-lg-4 mb-4 order-first order-lg-last">
      <div>
        <app-show-not-accepted-events></app-show-not-accepted-events>
      </div>
      <div>
        <app-open-work-shift-list></app-open-work-shift-list>
      </div>
      <div>
        <app-show-my-shift-swap></app-show-my-shift-swap>
      </div>
      <div>
        <app-show-shift-for-swap></app-show-shift-for-swap>
      </div>
    </div>
    <div class="row mb-4" style="padding-bottom: 10px">
      <div class="col-lg-6">
        <p class="h5 text-center mt-3">{{ 'MY WORK SHIFTS' | translate }}</p>
        <div class="card shadow">
          <div id="show-employee-work-shifts" class="card-body"
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px">
            <app-show-employee-work-shifts></app-show-employee-work-shifts>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4" style="padding-bottom: 10px;">
      <div class="col-lg-6">
        <p class="h5 text-center mt-3">{{ 'RECORDED TIME OVERVIEW' | translate }}</p>
        <div class="card shadow">
          <div id="employee-time-list" class="card-body"
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px">
            <app-day-week-month-chart></app-day-week-month-chart>
            <hr class="mt-2 mb-3" />
            <app-employee-time-list></app-employee-time-list>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-4" style="padding-bottom: 10px">
        <p class="h5 text-center mt-3">{{ 'CALENDAR VACATIONS AND ABSENCES' | translate }}</p>
        <div class="card shadow">
          <div id="employee-absence-calendar" class="card-body"
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px">
            <app-employee-absence-calendar></app-employee-absence-calendar>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4" style="padding-bottom: 10px">
        <p class="h5 text-center mt-3">{{ 'WEEKLY AVAILABILITY PLANNER' | translate }}</p>
        <div class="card shadow">
          <div id="availability-planner" class="card-body"
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px">
            <app-availability-planner></app-availability-planner>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div id="absence-holiday-chart">
          <div class="card shadow mb-4">
            <div class="card-body" style="height: 500px; width: 100%">
              <app-absence-holiday-chart></app-absence-holiday-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4" style="padding-top: 100px;"></div>

  </div>
  <!-- This is is not Mobile View -->
  <div *ngIf="!isMobile">
    <div class="container-fluid">
      <div class="row">
        <!-- Employee Notification Section: This is displayed first in both desktop and mobile views -->
        <div id="employee-notification" class="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div class="card shadow">
            <div class="card-body p-0">
              <app-show-not-accepted-events></app-show-not-accepted-events>
              <app-open-work-shift-list></app-open-work-shift-list>
              <app-show-my-shift-swap></app-show-my-shift-swap>
              <app-show-shift-for-swap></app-show-shift-for-swap>
            </div>
          </div>
        </div>
        <!-- Other Components -->
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div class="card shadow">
            <div class="card-body p-0">
              <app-show-employee-work-shifts></app-show-employee-work-shifts>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div class="card shadow">
            <div class="card-body p-0">
              <app-employee-absence-calendar></app-employee-absence-calendar>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div class="card shadow">
            <div class="card-body p-0">
              <app-availability-planner></app-availability-planner>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div class="card shadow">
            <div class="card-body" style="height: 500px; width: 100%">
              <app-absence-holiday-chart></app-absence-holiday-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4" style="padding-top: 100px;"></div>
  </div>
  <app-floating-action-button></app-floating-action-button>
</ion-content>