import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-input-editable',
  templateUrl: './input-editable.component.html',
  styleUrls: ['./input-editable.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEditableComponent),
      multi: true,
    },
  ],
})
export class InputEditableComponent implements OnInit {
  onChange: (val) => void;
  onTouched: () => void;

  @Output() onSave = new EventEmitter<boolean>();

  @Input() showTimepicker: false;
  @Input() showNormalInput: false;
  @Input() showDropdownInput: false;
  @Input() showAutocomplete: false;
  @Input() dropdownListe: { key: string | number; value: string }[] = [];

  selectedTime: string = '';
  @Input() validatorsPattern: string = '';
  @Input() suggestions: string[] = [];
  timeInputControl = new FormControl('', [
    Validators.required,
    Validators.pattern(this.validatorsPattern),
  ]);
  filteredSuggestions: string[] = [];

  hour = {
    StarTime: '',
    EndTime: '',
  };

  value: any;
  originalValue: any; // Speichern des ursprünglichen Werts, um bei Abbruch darauf zurückzusetzen

  dropdownValue: string;
  currentIcon = 'edit';
  editing: boolean = false; // Status, ob gerade bearbeitet wird

  constructor() {}

  ngOnInit(): void {}

  getDropdownValue(key): string {
    if (
      this.dropdownListe.length > 0 &&
      typeof key === 'number' &&
      key >= 0 &&
      key < this.dropdownListe.length
    ) {
      this.dropdownValue = this.dropdownListe.find(
        (dropdown) => dropdown.key === key
      ).value;
      return this.dropdownValue;
    }

    if (
      this.dropdownListe.length > 0 &&
      typeof key === 'string' &&
      this.dropdownListe.length
    ) {
      this.dropdownValue = this.dropdownListe.find(
        (dropdown) => dropdown.key === key
      ).value;
      return this.dropdownValue;
    }
    return '';
  }

  writeValue(value: any): void {
    this.value =
      value || (typeof value === 'number' && value >= 0) ? value : '';
    this.originalValue = this.value; // Speichern des ursprünglichen Werts
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changeButtonStatus(): void {
    if (this.currentIcon === 'check') {
      this.onSave.emit(true);
    }
    this.currentIcon = this.currentIcon === 'edit' ? 'check' : 'edit';
    this.editing = !this.editing;

    this.onChange(this.value);
  }

  cancelEdit(): void {
    this.value = this.originalValue; // Zurücksetzen auf den ursprünglichen Wert
    this.currentIcon = 'edit';
    this.editing = false;
  }

  onInputChange(event: any) {
    const inputValue = event.target.value.toLowerCase();
    this.filteredSuggestions = this.suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue)
    );
  }

  selectSuggestion(event: MatAutocompleteSelectedEvent, suggestion: string) {
    this.value = suggestion;
  }
}
