import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportsService } from '../services/reports.service';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.css'],
})
export class ReportsPageComponent {
  startDate: string = '';
  endDate: string = '';
  employeeId: number = 0;
  departmentIds: number[] = [];
  constructor(
    public service: ReportsService,
    private translate: TranslateService
  ) {
    // // start of current month and put it in the startDate
    // const startDate = new Date();
    // startDate.setDate(1);
    // this.startDate = startDate.toString();

    // // end of current month
    // const endDate = new Date();
    // endDate.setMonth(endDate.getMonth() + 1);
    // endDate.setDate(0);
    // this.endDate = endDate.toString();

    // can you give mir temproary satart date of 01.07 and end date of 31.07
    this.startDate = 'Mon Jul 01 2024 00:00:00 GMT+0200';
    this.endDate = 'Wed Jul 31 2024 00:00:00 GMT+0200';
  }

  ngOnInit(): void {}
  handleFilterApplied(filterData: any): void {
    this.startDate = filterData.startDate.toString();
    this.endDate = filterData.endDate.toString();
    this.employeeId = filterData.employee;
    this.departmentIds = filterData.department;
  }
}
