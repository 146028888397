import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { HttpService } from 'src/app/core/services/http/http.service';
import { Division } from '../model/division';
import { DivisionDepartments } from '../model/division-departments';

@Injectable({
  providedIn: 'root',
})
export class DivisionService {
  constructor(private httpClient: HttpService) {}

  private divisionListSource: BehaviorSubject<Division[]> = new BehaviorSubject(
    []
  );
  divisionList = this.divisionListSource.asObservable();

  private divisionDepartmentsListSource: BehaviorSubject<
    DivisionDepartments[]
  > = new BehaviorSubject([]);
  divisionDepartmentsList = this.divisionDepartmentsListSource.asObservable();

  getDivisionList(): void {
    this.httpClient
      .requestCall(ApiEndPoints.DivisionList, ApiMethod.GET)
      .subscribe((res) => {
        const DivisionList = res as Division[];
        this.divisionListSource.next(DivisionList);
      });
  }

  getDivisionWithDepartmentsList(): void {
    this.httpClient
      .requestCall(ApiEndPoints.DivisionWithDepartmentsList, ApiMethod.GET)
      .subscribe((res) => {
        const DivisionList = res as DivisionDepartments[];
        this.divisionDepartmentsListSource.next(DivisionList);
      });
  }


}
