import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { JobType, UserRoles } from 'src/app/core/services/const';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { Departement } from 'src/app/modules/departement/model/departement';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { Skill } from 'src/app/modules/skill/model/departement';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import { EmployeeService } from '../../../employee/services/employee.service';
import { Employee } from '../../model/employee.model';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css'],
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  userRoles = UserRoles;
  form: UntypedFormGroup;
  userRolesList: string[] = [];

  employeeHaseEmail = false;

  minEndTime = dayjs().startOf('day').set('h', 8).format('HH:mm');

  maxEndTime = dayjs().startOf('day').set('h', 10).format('HH:mm');

  hour = {
    hoursPerDay: '08:00',
  };

  jobTypes = [
    { id: JobType.FullTime, label: this.translate.instant('FULL-TIME') },
    { id: JobType.PartTime, label: this.translate.instant('PART-TIME') },
    { id: JobType.MiniJob, label: this.translate.instant('MINI-JOB') },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    public service: EmployeeService,
    public departementService: DepartementService,
    public skillService: SkillService,
    private translate: TranslateService,
    public authService: AuthService
  ) {
    this.userRolesList = Object.keys(this.userRoles).filter(
      (f) => !isNaN(Number(f))
    );
  }

  private subscriptionDepartmentList: Subscription;
  private subscriptionSkillList: Subscription;
  departmentList = [];
  skillList = [{ Name: 'Select first a Department', Id: null }];

  departmentValue = new UntypedFormControl();
  skillValue = new UntypedFormControl();
  skillPlaceholderText = this.translate.instant('FIRST CHOOSE A DEPARTMENT');

  formModel: Employee = {
    Id: 0,
    FirstName: null,
    LastName: null,
    Gender: 0,
    CivilStatus: 0,
    MobileNo: null,
    HomePhoneNo: null,
    Street1: null,
    City: null,
    ZipCode: null,
    ProfileImage: null,
    DepartmentList: null,
    Department: null,
    WorkingTime: 0,
    Role: 2,
    Email: '',
    Holidays: 0,
    SkillList: null,
    IsActive: true,
    EmailStatus: 0,
    WorkMinutesPerDay: 0,
    WorkDaysPerWeek: 0,
    JobType: JobType.Nothing,
  };

  contactMethods = [
    { id: 1, label: 'Weekly' },
    { id: 2, label: 'Monthly' },
  ];

  userRole = [
    { id: 2, label: 'Employee' },
    { id: 3, label: 'Team Leader' },
  ];

  ngOnInit(): void {
    if (this.data && this.data.employee && this.data.employee.Id) {
      this.formModel = {
        Id: this.data.employee.Id,
        FirstName: this.data.employee.FirstName,
        LastName: this.data.employee.LastName,
        Gender: this.data.employee.Gender,
        CivilStatus: this.data.employee.CivilStatus,
        MobileNo: this.data.employee.MobileNo,
        HomePhoneNo: this.data.employee.HomePhoneNo,
        Street1: this.data.employee.Street1,
        City: this.data.employee.City,
        ZipCode: this.data.employee.ZipCode,
        ProfileImage: this.data.employee.ProfileImage,
        DepartmentList: this.data.employee.DepartmentList,
        Department: this.data.employee.Department,
        WorkingTime: this.data.employee.WorkingHours,
        Role: this.data.employee.Role,
        Email: this.data.employee.Email,
        Holidays: this.data.employee.Holidays,
        SkillList: this.data.employee.SkillList,
        IsActive: this.data.employee.IsActive,
        EmailStatus: this.data.employee.EmailStatus,
        WorkMinutesPerDay: this.data.employee.WorkMinutesPerDay,
        WorkDaysPerWeek: this.data.employee.WorkDaysPerWeek,
        JobType: this.data.employee.JobType || JobType.Nothing,
      };

      // check if the employee has an valid email address with regex
      this.employeeHaseEmail = this.data.employee.Email.match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      );

      this.hour.hoursPerDay = dayjs
        .utc()
        .startOf('day')
        .add(this.data.employee.WorkMinutesPerDay, 'minutes')
        .format('HH:mm');

      this.onDepartmentChange({ value: this.data.employee.DepartmentList });
    } else {
      this.formModel = {
        Id: 0,
        FirstName: '',
        LastName: '',
        Gender: 0,
        CivilStatus: 0,
        MobileNo: '',
        HomePhoneNo: '',
        Street1: '',
        City: '',
        ZipCode: '',
        ProfileImage: '',
        DepartmentList: null,
        Department: null,
        WorkingTime: 0,
        Role: 2,
        Email: '',
        Holidays: 0,
        SkillList: null,
        IsActive: true,
        EmailStatus: 0,
        WorkMinutesPerDay: 0,
        WorkDaysPerWeek: 5,
        JobType: JobType.FullTime,
      };
    }

    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = list as Departement[];
      });
    this.departementService.getDepartementList();

    this.subscriptionSkillList = this.skillService.SkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
      }
    );
  }

  onSubmit(form: any): void {
    if (form) {
      // Zerlege die Stunden und Minuten
      const [hours, minutes] = this.hour.hoursPerDay.split(':').map(Number);

      if (!isNaN(hours) && !isNaN(minutes)) {
        // Konvertiere Stunden und Minuten in Gesamtminuten
        form.WorkMinutesPerDay = dayjs.duration({ hours, minutes }).asMinutes();

        if (form.JobType !== JobType.MiniJob) {
          const workInMinuts = form.WorkMinutesPerDay * form.WorkDaysPerWeek;
          // Konvertiere in Stunden mit 2 Dezimalstellen
          form.WorkingTime = +(workInMinuts / 60).toFixed(2);
        }

        // Füge den Arbeitgeber hinzu
        this.service.addEmployer(form as Employee);
      }
    }

    // Schließe das Formular
    this.onClose();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onDepartmentChange(event) {
    this.skillList = [];
    this.skillService.getSkillList(event.value);
    if (event.value.length > 0) {
      this.skillPlaceholderText = this.translate.instant(
        'CHOOSE A QUALIFICATION'
      );
    } else {
      this.skillPlaceholderText = this.translate.instant(
        'FIRST CHOOSE A DEPARTMENT'
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptionDepartmentList.unsubscribe();
    this.subscriptionSkillList.unsubscribe();
  }
}
