<div class="row" style="padding-top: 25px">
  <div class="col-12">
    <h3 class="page-title">{{ "SETTINGS" | translate }}</h3>
  </div>
  <div class="row" style="padding-right: 0px; padding-top: 15px">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-header">
          {{ "GENERAL" | translate }}
        </div>
        <div class="card-body">
          <app-company-settings></app-company-settings>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          {{ "TIME TRACKING AND WORKING HOURS REGULATIONS" | translate }}
        </div>
        <div class="card-body">
          <app-time-tracking-settings></app-time-tracking-settings>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          {{ "SHIFT SWAP SETTINGS" | translate }}
        </div>
        <div class="card-body">
          <app-shift-swap-settings></app-shift-swap-settings>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="card">
        <div class="card-header">
          {{ "Department" | translate }}
          <button mat-icon-button [matMenuTriggerFor]="menuDepartment">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menuDepartment="matMenu">
            <button mat-menu-item (click)="addDepartement()">
              <mat-icon>add</mat-icon>
              <span>{{ "ADD" | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="card-body">
          <app-departement-list
            (DepartementId)="setDepartementId($event)"
            (DepartementName)="setDepartementName($event)"
          >
          </app-departement-list>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          {{ "QUALIFICATION" | translate }} <b>{{ DepartementName }}</b>
          <button mat-icon-button [matMenuTriggerFor]="menuqualification">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menuqualification="matMenu">
            <button mat-menu-item (click)="addSkill()">
              <mat-icon>add</mat-icon>
              <span>{{ "ADD" | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <div class="card-body">
          <app-skill-list [departmentId]="DepartementId"></app-skill-list>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="card">
        <div class="card-header">Subscription</div>
        <div class="card-body">
          <app-current-subscription></app-current-subscription>
        </div>
      </div>
    </div>
  </div>
</div>
