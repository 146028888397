import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import {
  ApiEndPoints,
  ApiMethod,
  ClockEventType,
} from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { AddClockEvent } from '../model/add-clock-event.model';
import { ClockEventOneDayDto } from '../model/clock-event-dto.model';
import { DayWeekMonth } from '../model/day-week-month';
import { UpdateClockEvent } from '../model/update-clock-event.model';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TimeRecordingService {
  private timer: any;
  private totalSecondsWorkedToday: number = 0;

  private clockEvenSource: BehaviorSubject<ClockEventOneDayDto> =
    new BehaviorSubject(null);
  clockEvent = this.clockEvenSource.asObservable();

  private clockEventListSource: BehaviorSubject<any> = new BehaviorSubject([]);
  clockEventList = this.clockEventListSource.asObservable();

  private clockEventForDayListSource: BehaviorSubject<any> =
    new BehaviorSubject([]);
  clockEventForDay = this.clockEventForDayListSource.asObservable();

  private employeeStatusListSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  employeeStatusList = this.employeeStatusListSource.asObservable();

  private employeeStatusSource: BehaviorSubject<any> = new BehaviorSubject([]);
  employeeStatus = this.employeeStatusSource.asObservable();

  private timeRecordingTimelineSource: BehaviorSubject<any> =
    new BehaviorSubject(null);
  timeRecordingTimeline = this.timeRecordingTimelineSource.asObservable();

  private dayWeekMonthStatsQuerySource: BehaviorSubject<DayWeekMonth> =
    new BehaviorSubject(null);
  dayWeekMonthStatsQuery = this.dayWeekMonthStatsQuerySource.asObservable();

  private employeeTimeRecoringEventListSource: BehaviorSubject<any> =
    new BehaviorSubject(null);
  employeeTimelineEventList =
    this.employeeTimeRecoringEventListSource.asObservable();

  private workedTimeDisplaySource: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  workedTimeDisplay = this.workedTimeDisplaySource.asObservable();

  private ComplianceMessageSource: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  ComplianceMessage = this.ComplianceMessageSource.asObservable();
  oneDay: any;
  currentWeekStart: any;

  private updateSubscription: Subscription;
  private employeeLiveStatusSource: BehaviorSubject<any[]> =
    new BehaviorSubject([]);
  employeeLiveStatus$ = this.employeeLiveStatusSource.asObservable();

  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  addClockEvent(event: AddClockEvent): Promise<void> {
    const endPoint = event.EmployeeId
      ? ApiEndPoints.AddClockEventAdmin
      : ApiEndPoints.AddClockEvent;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.requestCall(endPoint, ApiMethod.POST, event).subscribe(
        (res: number) => {
          if (res > 0) {
            this.toastr.success(
              this.translate.instant('Clock event added successfully'),
              this.translate.instant('Success')
            );
            resolve();
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateClockEvent(event: UpdateClockEvent): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .requestCall(ApiEndPoints.UpdateClockEvent, ApiMethod.PUT, event)
        .subscribe(
          (res: number) => {
            if (res > 0) {
              this.toastr.success(
                this.translate.instant('Clock event updated successfully'),
                this.translate.instant('Success')
              );
              resolve();
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteClockEvent(id: number, isAdmin: boolean): Promise<void> {
    const endPoint = isAdmin
      ? ApiEndPoints.DeleteClockEventAdmin
      : ApiEndPoints.DeleteClockEvent;
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .requestCall(`${endPoint}/${id}`, ApiMethod.DELETE)
        .subscribe(
          (res: boolean) => {
            if (res) {
              this.toastr.success(
                this.translate.instant('Clock event deleted successfully'),
                this.translate.instant('Success')
              );
              resolve();
            } else {
              reject(new Error('Failed to delete clock event'));
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getAllClockEvents(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetAllClockEvents, ApiMethod.GET)
      .subscribe((list: any) => {
        this.clockEvenSource.next(list);
      });
  }

  getEmployeeLastStatus(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetEmployeeLastStatus, ApiMethod.GET)
      .subscribe((status: any) => {
        this.employeeStatusSource.next(status);
        this.totalSecondsWorkedToday = status.TotalSecondsWorkedToday;

        if (status.LastEventType == ClockEventType.ClockIn) {
          this.workedTimeDisplaySource.next(true);
        } else {
          this.workedTimeDisplaySource.next(false);
        }

        if (status.ComplianceMessage.length > 0) {
          this.ComplianceMessageSource.next(true);
        } else {
          this.ComplianceMessageSource.next(false);
        }
      });
  }

  getEmployeeStatus(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetEmployeeStatus, ApiMethod.GET)
      .subscribe((list: any) => {
        this.employeeStatusListSource.next(list);
      });
  }

  getDayWeekMonthStatsQuery(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetDayWeekMonthStatsQuery, ApiMethod.GET)
      .subscribe((dayWeekMonth: DayWeekMonth) => {
        this.dayWeekMonthStatsQuerySource.next(dayWeekMonth);
      });
  }

  getTimeRecordingTimelineForCompany(
    currentWeekStart = null,
    oneDay,
    searchKeywords = '*'
  ): void {
    searchKeywords = searchKeywords ? searchKeywords : '*';

    if (oneDay == null) {
      oneDay = this.oneDay;
    } else {
      this.oneDay = oneDay;
    }

    if (currentWeekStart == null) {
      currentWeekStart = this.currentWeekStart;
    } else {
      this.currentWeekStart = currentWeekStart;
    }

    currentWeekStart = dayjs(currentWeekStart)
      .utcOffset(0, true)
      .format('YYYY-MM-DD');
    let endPoint =
      ApiEndPoints.GetTimeRecordingTimelineForCompany +
      '/' +
      currentWeekStart +
      '/' +
      oneDay +
      '/' +
      searchKeywords;

    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((result: any) => {
        result.CalenderEvents = result.CalenderEvents.map((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          this.setColorAndClassForEvent(entry);
          return entry;
        });

        this.employeeTimeRecoringEventListSource.next({
          calenderEvents: result.CalenderEvents,
          calenderResources: result.CalenderResources,
          calendarWeekStart: null,
        } as any);
      });
  }

  getAllClockEventsForDay(
    date: string,
    employeeId: number,
    departmentId: number
  ): void {
    const dateStart = dayjs(date).utcOffset(0, true).format('YYYY-MM-DD');
    this.httpClient
      .requestCall(
        `${ApiEndPoints.GetAllClockEventsForDay}/${dateStart}/${employeeId}/${departmentId}`,
        ApiMethod.GET
      )
      .subscribe((list: ClockEventOneDayDto) => {
        list.ClockEvents = list.ClockEvents.filter(
          (event) =>
            event.EventType === ClockEventType.ClockIn ||
            event.EventType === ClockEventType.BreakStart
        );
        this.clockEvenSource.next(list);
      });
  }

  getLiveStatus(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetLiveStatus, ApiMethod.GET)
      .subscribe((liveStatusList: any[]) => {
        this.employeeLiveStatusSource.next(liveStatusList);
      });
  }

  // Method to start automatic refresh every 60 seconds
  startAutoRefresh(intervalTime: number = 60000): void {
    this.updateSubscription = interval(intervalTime).subscribe(() => {
      this.getLiveStatus();
    });
  }

  // Method to stop auto-refresh
  stopAutoRefresh(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  resetClockEvent(): void {
    this.clockEvenSource.next(null);
  }

  getClockEventsForMultipleDays(startDate: Date, endDate: Date): void {
    const dateStart = dayjs(startDate).format('YYYY-MM-DD');
    const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');
    this.httpClient
      .requestCall(
        `${ApiEndPoints.GetClockEventsForMultipleDays}/${dateStart}/${endDateFormatted}`,
        ApiMethod.GET
      )
      .subscribe((list: ClockEventOneDayDto[]) => {
        list.forEach((event) => {
          event.ClockEvents = event.ClockEvents.filter(
            (event) =>
              event.EventType === ClockEventType.ClockIn ||
              event.EventType === ClockEventType.BreakStart
          );
        });

        this.clockEventListSource.next(list);
      });
  }

  setColorAndClassForEvent(event: any): void {
    event.className =
      'vertical-line-time-recordings ' + 'event-animation-scale';
    event.color = '#c1b4f84d';
    event.textColor = '#000000';
  }
}
