import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { BottomNavigationEmployeeService } from './bottom-navigation-employee.service';

@Component({
  selector: 'app-bottom-navigation-employee',
  templateUrl: './bottom-navigation-employee.component.html',
  styleUrls: ['./bottom-navigation-employee.component.css'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class BottomNavigationEmployeeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  sections: NodeListOf<Element>;
  content: IonContent;
  showNotificationBadge: boolean = false;
  version: string = '0.0.0'; // Setzen Sie assets/version.txt Ihre Versionsnummer

  constructor(
    public authService: AuthService,
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public bottomNavigationEmployeeService: BottomNavigationEmployeeService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // get if notification badge is set or not
    this.bottomNavigationEmployeeService.showNotificationBadge$
      .pipe(takeUntil(this.destroy$))
      .subscribe((show) => {
        this.showNotificationBadge = show;
      });

    this.bottomNavigationEmployeeService.scrollEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((scrollY) => {
        this.scrollActive(scrollY);
      });

    this.http
      .get('assets/version.txt', { responseType: 'text' })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.version = data.trim();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.checkScrollability();
  }

  private checkScrollability(): void {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      // Access the content element from the shared service
      this.content =
        this.bottomNavigationEmployeeService.getScrollableContentElement();

      this.sections = document.querySelectorAll('div[id]');

      this.scrollActive(0);
    }, 2000);
  }

  async scrollActive(scrollY): Promise<void> {
    const scrollElement = await this.content?.getScrollElement();
    if (!scrollElement) {
      return;
    }
    const midScreen = scrollElement.clientHeight / 2 + scrollY;
    const topScreen = scrollElement.clientHeight / 4 + scrollY;

    this.sections = document.querySelectorAll('div[id]');

    let hasActiveSection = false;

    this.sections.forEach((current: any) => {
      // this is not base on math or science, just try and error :)
      const offsetTop = current.offsetParent?.offsetTop;
      const offsetExtra = current.offsetParent?.offsetTop
        ? 0
        : current.clientHeight - 45;
      if (
        offsetTop + offsetExtra + 320 >= topScreen &&
        offsetTop <= midScreen &&
        !hasActiveSection
      ) {
        hasActiveSection = true;
        this.setStatus(current);
      }
    });
    hasActiveSection = false;
  }

  setStatus(activeSection) {
    if (activeSection) {
      this.sections.forEach((section: any) => {
        const sectionId = section.getAttribute('id');
        const navLink = document.querySelector(
          '.nav__menu a[id="' + sectionId + '-nav' + '"]'
        );
        if (navLink) {
          if (section === activeSection) {
            navLink.classList.add('active-link');
          } else {
            navLink.classList.remove('active-link');
          }
        }
      });
    }
  }

  onNavClick(elementId): void {
    const element = document.getElementById(elementId);
    if (element) {
      const offset = (element.offsetParent as HTMLElement).offsetTop - 40;
      this.content.scrollToPoint(0, offset, 800);
      element.style.opacity = '0';
      element.style.transition = 'opacity 0.3s ease-out';
      setTimeout(() => {
        element.style.opacity = '1';
        element.style.transition = 'none';
        this.scrollActive(offset);
      }, 600);
    }
  }

  onLogoutClick(): void {
    this.authService.goToLogin();
  }

  onChangePassword(): void {
    this.router.navigateByUrl('/change-password');
  }
}
