export const environment = {
  imagesURL: 'https://api-test.fixplaner.com/Resources/Images/',
  apiURL: 'https://api-test.fixplaner.com/api',
  production: true,
  firebase: {
    apiKey: 'AIzaSyBWu9YdJI7OJoz156OyB05XICsqR85xkCc',
    authDomain: 'x-planner-3d992.firebaseapp.com',
    projectId: 'x-planner-3d992',
    storageBucket: 'x-planner-3d992.appspot.com',
    messagingSenderId: '125874005497',
    appId: '1:125874005497:web:1d917d01c9b7d7c6e10928',
    measurementId: 'G-WL1WGNJX8G',
  },
  matomo: {
    url: 'https://analytics.fixplaner.com/',
    siteId: 1,
  },
};
// apiURL: 'https://xplanner-dev.azurewebsites.net/api',
// apiURL: 'https://localhost:44362/api',
