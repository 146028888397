import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { Departement } from '../../model/departement';
import { DepartementService } from '../../services/departement.service';
import { AddDepartementComponent } from '../add-departement/add-departement.component';

@Component({
  selector: 'app-departement-list',
  templateUrl: './departement-list.component.html',
  styleUrls: ['./departement-list.component.css'],
})
export class DepartementListComponent implements OnInit, OnDestroy {
  private subscriptionDepartementList: Subscription;
  listData: Departement[];
  // what to do to pass value autside the component to the parent component using @Output and EventEmitter in angular

  @Output() DepartementId = new EventEmitter();
  @Output() DepartementName = new EventEmitter();

  currentDepartementId: number;

  alertMessage: string = this.translate.instant(
    'THE LIST IS STILL EMPTY PLEASE ADD A DEPARTMENT USING THE OPTIONS AT THE TOP RIGHT'
  );

  displayedColumns: string[] = [
    'DepartementName',
    'EmployeeCount',
    'Actions',
    'status',
  ];

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private service: DepartementService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscriptionDepartementList =
      this.service.DepartementListWithEmployeeCount.subscribe((list) => {
        this.listData = list as Departement[];
        if (this.listData.length > 0) {
          this.getRecord(this.listData[0]?.Id, this.listData[0]?.Name);
        }
      });
    this.service.getDepartementListWithEmployeeCount();
  }

  onDepartementeDelete(id: number, Name: string): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    const textDialog =
      this.translate.instant(
        'Are you sure you want to delete this Departement'
      ) +
      '?<br/><b>' +
      Name +
      '</b> ';
    this.dialogRef.componentInstance.confirmMessage = textDialog;

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteDepartment(id);
      }
      this.dialogRef = null;
    });
  }
  getRecord(id: number, name: string): void {
    this.DepartementId.emit(id);
    this.DepartementName.emit(name);
    this.currentDepartementId = id;
  }
  ngOnDestroy(): void {
    this.subscriptionDepartementList.unsubscribe();
  }

  onDepartementeUpdate(departementId: number): void {
    const departement = this.service.getDepartementById(departementId);
    this.updateDepartement(departement);
  }

  updateDepartement(departement): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { departement };
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddDepartementComponent, dialogConfig);
  }

  activeRowIndex: number = 0; // Default to the first row

  setActiveRow(index: number) {
    this.activeRowIndex = index;
  }
}
