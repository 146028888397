<div class="modal-dialog modal-lg" role="document" style="width: 600px; max-width: 90vw;">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'ADD NEW QUALIFICATIONS'| translate}}</h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(form)">
        <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModel.Id" />
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="DepartmentId">{{'Department'| translate}}</label>
            <mat-select #DepartmentId="ngModel" name="DepartmentId" id="DepartmentId" class="form-control"
              [(ngModel)]="formModel.DepartmentId">
              <mat-option *ngFor="let department of departmentList" [value]="department.Id">
                {{department.Name}}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="InputFirstName">{{'QUALIFICATION'| translate}}</label>
            <input autocomplete="off" type="text" class="form-control" id="Name" aria-describedby="emailHelp"
              #FirstName="ngModel" placeholder="{{'ENTER QUALIFICATION NAME'| translate}}" name="Name"
              [(ngModel)]="formModel.Name" required>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="iconType">{{'ICON CATEGORIES'|translate}}</label>
            <mat-select (selectionChange)="onIconTypeChange($event)" id="iconType" class="form-control "
              placeholder="{{'SELECT CATEGORIES FOR SYMBOLS' | translate}}" [formControl]="iconValue" multiple
              [(ngModel)]="formModel.IconType">
              <mat-option *ngFor="let iconType of iconTypeList" [value]="iconType.Id">
                {{iconType.Name}}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="Icon">{{'ICON'| translate}}</label>
            <mat-select #Icon="ngModel" name="Icon" id="Icon" class="form-control" [(ngModel)]="formModel.Icon"
              [placeholder]="iconList.length === 1 ? ('SELECT CATEGORIES FOR SYMBOLS' | translate) : ''"
              (selectionChange)="onIconChange($event)">
              <mat-select-trigger>
                <span *ngIf="selectedValue">
                  <i [ngClass]="selectedValue.IconCode + ' fa-lg'"></i>
                  {{selectedValue.Name | translate}}
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let icon of iconList" [value]="icon.Id"> <i [ngClass]="icon.IconCode + ' fa-lg'"></i>
                {{icon.Name | translate}}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button" data-dismiss="modal">{{'Close'|
        translate}}</button>
      <button class="btn btn-primary active" form="addEmployee" type="submit" [disabled]="form.invalid">
        {{'Save'| translate}}
      </button>
    </div>
  </div>
</div>