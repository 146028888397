import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { HttpService } from 'src/app/core/services/http/http.service';
import { v4 as uuidv4 } from 'uuid';
import { Conflicts } from '../../admin/model/Conflicts.model';
import { HolidaysChart } from '../../admin/model/holidaysChart.model';
import { AuthService } from '../../auth/services/auth/auth.service';
import { AbsenceRequests } from '../model/AbsenceRequests.model';
import { AbsenceSummaryDto } from '../model/absence-summary-dto.model';
import { Absence } from '../model/absence.model';

@Injectable({
  providedIn: 'root',
})
export class AbsenceService {
  eventVacationColor: string;
  eventSickColor: string;
  constructor(
    private httpClient: HttpService,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.eventVacationColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--event-vacation')
      .trim();

    this.eventSickColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--event-sick')
      .trim();
  }

  // -------------------employeeAbsence--------------------------------------
  private employeeAbsenceListSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  employeeAbsenceList = this.employeeAbsenceListSource.asObservable();
  // ---------------------------------------------------------

  // ------------------------absenceRequests---------------------------------
  private absenceRequestsListSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  absenceRequestsList = this.absenceRequestsListSource.asObservable();
  // ---------------------------------------------------------

  // ---------------------holidays------------------------------------
  private holidaysForEmployeeSource: BehaviorSubject<HolidaysChart> =
    new BehaviorSubject(new HolidaysChart());
  holidaysForEmployee = this.holidaysForEmployeeSource.asObservable();
  // ---------------------------------------------------------

  // ---------------------Conflicts------------------------------------
  private holidaysConflictsWithWorkingShiftSource: BehaviorSubject<any> =
    new BehaviorSubject(new Conflicts());
  holidaysConflictsWithWorkingShift =
    this.holidaysConflictsWithWorkingShiftSource.asObservable();
  // ---------------------------------------------------------

  // ---------------------Conflicts------------------------------------
  private getAbsenceSummaryDataSource: BehaviorSubject<AbsenceSummaryDto> =
    new BehaviorSubject({} as AbsenceSummaryDto);
  getAbsenceSummaryData = this.getAbsenceSummaryDataSource.asObservable();
  // ---------------------------------------------------------

  absenceCount = 0;
  submitAbsence(absence: Absence): HttpService {
    const absenceObj = {
      StarTime: new Date(absence.StarTime),
      EndTime: new Date(absence.EndTime),
      EmployeeId: +absence.EmployeeId,
      AbsenceType: +absence.AbsenceType,
      EventId: absence.EventId,
      Comment: absence.Comment,
      AllDay: absence.AllDay,
    };

    if (absence.Id > 0) {
      return this.httpClient
        .requestCall(ApiEndPoints.UpdateEmployee, ApiMethod.POST, absenceObj)
        .subscribe((res: any) => {
          this.GetAbsenceRequests();
        });
    }
    absenceObj.EventId = uuidv4();

    let endPoint = ApiEndPoints.AddAbsence;
    if (!this.authService.isManegeOrTeamLeader) {
      absenceObj.EmployeeId = 0;
      endPoint = ApiEndPoints.AddAbsenceForMe;
    }

    return this.httpClient
      .requestCall(endPoint, ApiMethod.POST, absenceObj)
      .subscribe((res: any) => {
        if (res > 0) {
          this.toastr.success(
            this.translate.instant(
              'YOU HAVE ADDED A NEW ABSENCE TO YOUR CALENDAR'
            ),
            this.translate.instant('NEW ABSENCE')
          );
        }
        if (this.authService.isManegeOrTeamLeader) {
          this.GetAbsenceRequests();
        }
        this.getAbsence(new Date(absence.StarTime));
      });
  }

  getAbsence(currentWeekStart): HttpService {
    let endPoint = ApiEndPoints.GetAbsenceCalender;
    if (!this.authService.isManegeOrTeamLeader) {
      endPoint = ApiEndPoints.GetEmployeeAbsence;
    }

    currentWeekStart = dayjs(currentWeekStart).format('YYYY-MM-DD');

    return this.httpClient
      .requestCall(endPoint + '/' + currentWeekStart, ApiMethod.GET)
      .subscribe((list: any) => {
        const clonedList = list.map((x) => Object.assign({}, x));
        clonedList.forEach((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          entry.end.setSeconds(entry.end.getSeconds() + 1);
          this.setColorAndClassForEvent(entry);
        });
        this.employeeAbsenceListSource.next(clonedList as any[]);
      });
  }

  deleteAbsence(absenceId, startTime): HttpService {
    startTime = dayjs(startTime).format('YYYY-MM-DD');
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteAbsence, ApiMethod.POST, {
        AbsenceId: absenceId,
      })
      .subscribe((details: any) => {
        if (details) {
          this.toastr.success(
            this.translate.instant(
              'YOU HAVE SUCCESSFULLY DELETED THE ABSENCE FROM YOUR CALENDAR'
            ),
            this.translate.instant('ABSENCE DELETED')
          );
        }

        this.getAbsence(startTime);
        if (this.authService.isManegeOrTeamLeader) {
          this.GetAbsenceRequests();
        }
      });
  }
  GetAbsenceRequests(): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.GetAbsenceRequests, ApiMethod.GET)
      .subscribe((list: any) => {
        const clonedList = list.map((x) => Object.assign({}, x));
        clonedList.forEach((entry) => {
          entry.StarTime = new Date(entry.StarTime);
          entry.EndTime = new Date(entry.EndTime);
        });
        this.absenceRequestsListSource.next(clonedList as AbsenceRequests[]);
        this.absenceCount = clonedList.length;
      });
  }

  acceptRequestAbsence(id, startDay): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.UpdateAbsenceRequest, ApiMethod.POST, {
        AbsenceId: id,
        RequestStatus: 3,
      })
      .subscribe((list: any) => {
        // get current week start day from startDay
        startDay = dayjs(startDay).format('YYYY-MM-DD');
        this.getAbsence(startDay);
        this.GetAbsenceRequests();
      });
  }

  rejectRequestAbsence(id, startDay): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.UpdateAbsenceRequest, ApiMethod.POST, {
        AbsenceId: id,
        RequestStatus: 4,
      })
      .subscribe((list: any) => {
        // get current week start day from startDay
        startDay = dayjs(startDay).format('YYYY-MM-DD');
        this.getAbsence(startDay);
        this.GetAbsenceRequests();
      });
  }

  getHolidaysForEmployeeChart(employeeId = 0): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetHolidaysForEmployeeChart + '/' + employeeId,
        ApiMethod.GET
      )
      .subscribe((holidaysChart: HolidaysChart) => {
        this.holidaysForEmployeeSource.next(holidaysChart);
      });
  }

  getConflictsWithWorkingShift(absenceId): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetConflictsWithWorkingShift + '/' + absenceId,
        ApiMethod.GET
      )
      .subscribe((conflicts: Conflicts[]) => {
        conflicts.forEach((conflict) => {
          conflict.StarTime = new Date(conflict.StarTime);
          conflict.EndTime = new Date(conflict.EndTime);
        });
        this.holidaysConflictsWithWorkingShiftSource.next(conflicts);
      });
  }

  addOpenShiftByAbsenceIdCommand(workShift: any[], absenceId): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.AddOpenShiftByAbsenceIdCommand,
        ApiMethod.POST,
        {
          WorkShiftIdList: workShift,
          AbsenceId: absenceId,
        }
      )
      .subscribe((conflicts: Conflicts) => {
        this.holidaysConflictsWithWorkingShiftSource.next(conflicts);
      });
  }

  getAbsenceSummary(startDate: string, endDate: string) {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();
    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
    };

    this.httpClient
      .requestCall(ApiEndPoints.GetAbsenceSummary, ApiMethod.POST, postObject)
      .subscribe((res) => {
        const absenceSummary = res as AbsenceSummaryDto;
        if (absenceSummary) {
          this.getAbsenceSummaryDataSource.next(absenceSummary);
        }
      });
  }

  setColorAndClassForEvent(event: any): void {
    switch (event.absenceType) {
      case 1:
        event.color = this.eventVacationColor;
        break;
      case 2:
        event.color = this.eventSickColor;
        break;
      default:
        event.color = '';
        event.className = '';
        break;
    }

    if (event.requestStatus == 4) {
      event.color = '#a8a8a8';
    }
  }
}
