import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { LoaderService } from '../../core/services/loader/loader.service';

@Component({
  selector: 'app-mobile-page-header',
  templateUrl: './mobile-page-header.component.html',
  styleUrls: ['./mobile-page-header.component.css'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class MobilePageHeaderComponent implements OnInit {
  currentRoute: string;
  constructor(
    public authService: AuthService,
    public router: Router,
    public loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {}
  public showSidebar = false;
  public loading = false;
  openShiftNumber = 0;
  lang;
  ngOnInit(): void {
    this.changeDetectorRef.detach();
    this.loaderService.isLoading.subscribe((res) => {
      this.loading = res > 0;
      this.changeDetectorRef.detectChanges();
    });
    this.lang = this.translate.currentLang;
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
    this.changeDetectorRef.detectChanges();
  }
  changeLocale(lang: string): void {
    localStorage.setItem('lang', lang);
    window.location.reload();
    this.showSidebar = false;
  }

  openPersonProfile(): void {
    this.router.navigateByUrl('/profile');
    this.showSidebar = false;
  }

  onDashboardClick(): void {
    this.router.navigate(['/dashboard']);
    this.showSidebar = false;
  }

  onEmployeeDashboardClick(): void {
    this.router.navigate(['/employeedashboard']);
    this.showSidebar = false;
  }

  onEmployeeClick(): void {
    this.router.navigateByUrl('/employee');
    this.showSidebar = false;
  }

  onCompanyClick(): void {
    this.router.navigateByUrl('/company');
    this.showSidebar = false;
  }

  onAbsenceClick(): void {
    this.router.navigateByUrl('/absence');
    this.showSidebar = false;
  }

  onPlannerClick(): void {
    this.router.navigateByUrl('/planner');
    this.showSidebar = false;
  }
  onScheduleRequirementClick(): void {
    this.router.navigateByUrl('/requirement-planer');
    this.showSidebar = false;
  }

  onAdminSettingClick(): void {
    this.router.navigateByUrl('/admin-settings');
    this.showSidebar = false;
  }

  onResourcePlanClick(): void {
    this.router.navigateByUrl('/resource-plan');
    this.showSidebar = false;
  }

  onSettingsClick(): void {
    this.router.navigateByUrl('/settings');
    this.showSidebar = false;
  }

  onLogoutClick(): void {
    this.authService.goToLogin();
    this.showSidebar = false;
  }

  onChangePassword(): void {
    this.router.navigateByUrl('/change-password');
    this.showSidebar = false;
  }

  onDashboardClickImage(): void {
    if (this.authService.isManegeOrTeamLeader) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/employeedashboard');
    }
    this.showSidebar = false;
  }
}
