<div class="table-responsive">
  <table class="table table-striped">
    <tbody>
      <tr *ngFor="let event of clockEventList; let i = index">
        <td style="width: 25%;">
          <span>{{ event.eventTypeLabel }}</span>
        </td>
        <td style="width: 20%;">
          <span *ngIf="!event.editing">{{ event.formattedEventStart }}</span>
          <input *ngIf="event.editing" [(ngModel)]="event.editStartTime" type="time" class="form-control"
            (ngModelChange)="validateEventForUpdate(i)" />
        </td>
        <td style="width: 20%;">
          <span *ngIf="!event.editing">{{ event.formattedEventEnd }}</span>
          <input *ngIf="event.editing" [(ngModel)]="event.editEndTime" type="time" class="form-control"
            (ngModelChange)="validateEventForUpdate(i)" />
        </td>
        <td style="width: 35%; text-align: right;">
          <button style="margin-right: 6px;"
            *ngIf="!event.editing && event.EventEndId && (authService.isManegeOrTeamLeader || companySettingsService.CompanySettings.TimeTrackingSettings.EmployeeSettings.CanEditTimeRecord)"
            (click)="editEvent(i)" class="btn btn-sm btn-primary">
            <i class="fa fa-pencil"></i>
          </button>
          <button style="margin-right: 6px;" *ngIf="event.EventEndId === 0" (click)="stoptTimerForEmployee(i)"
            class="btn btn-sm btn-primary">
            <i class="fa-light fa-play-pause"></i>
          </button>
          <button
            *ngIf="!event.editing && (authService.isManegeOrTeamLeader || companySettingsService.CompanySettings.TimeTrackingSettings.EmployeeSettings.CanDeleteTimeRecord)"
            (click)="showDeleteDialog(i)" class="btn btn-sm btn-danger" [disabled]="event.EventEndId === 0">
            <i class="fa-light fa-trash-clock"></i>
          </button>
          <button [disabled]="!(validationError$.value === '')" [matTooltip]="validationError$.value"
            style="margin-right: 6px;" *ngIf="event.editing" (click)="saveEvent(i)" class="btn btn-sm btn-success">
            <i class="fa-light fa-download"></i>
          </button>
          <button *ngIf="event.editing" (click)="cancelEditEvent(i)" class="btn btn-sm btn-danger">
            <i class="fa-light fa-xmark"></i>
          </button>
        </td>
      </tr>
      <tr *ngIf="newEvent">
        <td style="width: 25%;">
          <mat-select #Role="ngModel" name="Role" id="Role" class="form-control" [(ngModel)]="newEvent.EventType">
            <mat-option *ngFor="let role of timeRecordes" [value]="role.id">{{ role.lable }}</mat-option>
          </mat-select>
        </td>
        <td style="width: 20%;">
          <input class="form-control" [(ngModel)]="newEvent.editStartTime" type="time"
            (ngModelChange)="validateNewEvent()" />
        </td>
        <td style="width: 20%;">
          <input class="form-control" [(ngModel)]="newEvent.editEndTime" type="time"
            (ngModelChange)="validateNewEvent()" />
        </td>
        <td style="width: 35%; text-align: right;">
          <button [disabled]="!(validationError$.value === '')" [matTooltip]="validationError$.value"
            style="margin-right: 6px;" (click)="saveNewEvent()" class="btn btn-sm btn-success">
            <i class="fa-light fa-download"></i>
          </button>
          <button (click)="cancelNewEvent()" class="btn btn-sm btn-danger">
            <i class="fa fa-times"></i>
          </button>
        </td>
      </tr>
      <tr
        *ngIf="!newEvent &&  (authService.isManegeOrTeamLeader || companySettingsService.CompanySettings.TimeTrackingSettings.EmployeeSettings.CanAddTimeRecord)">
        <td colspan="3"></td>
        <td style="width: 30%; text-align: right;">
          <button (click)="addNewEvent()" class="btn btn-sm btn-outline-primary">
            <i class="fa fa-plus"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="validationError$.value" class="alert alert-danger fadeInUpwards" role="alert">
    {{ validationError$.value }}
  </div>
</div>